import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import {  SimlarAds } from "../components";
import "../styles/adsDetails.scss";
import AdsSubmitButton from "../components/buttons/AdsSubmitButton";
import AdsDetailTable from "../components/adsDetailComponents/AdsDetailTable";
import userContext from "../context";
import { Helmet } from "react-helmet";

function AdsDetail() {
  const { adsSlug } = useParams();
  const [data, setData] = useState([]);
  const [simlarData, setSimlarData] = useState([]);
  const { setGetcategory, setGetSubCategory } = useContext(userContext);
  const category = data.PostCategory;
  const subCategory = data.PostSubCategory;

  setGetcategory(category);
  setGetSubCategory(subCategory);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/getsingleadspost/${adsSlug}`)
      .then((response) => {
        console.log("single", response.data);
        setData(response.data.AdsPost);
        setSimlarData(response.data.similarAdsPosts);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [adsSlug]);

  // const breadcrumbItems = [
  //   { path: ``, label: data.PostCategory },
  //   { path: ``, label: data.PostSubCategory },
  //   { path: ``, label: data?.PostDetails?.Location },
  //   { path: ``, label: data.postTittle },
  // ];

  return (
    <div className="container">
      {/* <Breadcrumb items={breadcrumbItems} /> */}
      <Helmet>
        <title>{`${data?.postTittle} - ${data?.PostDetails?.Location} - ${data?.PostDetails?.City}`} </title>
        <meta name="description" content={data?.postDescription} />
      </Helmet>
      <p className="ads-loaction">
        {data?.PostDetails?.Location} - {data?.PostDetails?.City}
      </p>

      <h1 className="m-0 ads-title"> {data.postTittle} </h1>

      <p className="ads-content">{data.postDescription}</p>

      <AdsSubmitButton className="ads-apply-det-btn" item={data}  />

      <AdsDetailTable data={data} />

      <SimlarAds data={data} simlarData={simlarData} />
    </div>
  );
}

export default AdsDetail;
