import React, { useState } from "react";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { AuthContent, PagePurpose, SuggesLink } from "../../components";
import AuthSocialMediaLinks from "../../components/authComponents/AuthSocialMediaLinks";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import toast, { Toaster } from "react-hot-toast";

function ResetPassWord() {
  const [hidePassword, setHidePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const { reset } = useParams();

  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const inputFields = [
    {
      type: hidePassword ? "text" : "password",
      placeholder: "Password",
      toggleVisibility: togglePasswordVisibility,
      hide: hidePassword,
      name: "password",
    },
    {
      type: hideConfirmPassword ? "text" : "password",
      placeholder: "Confirm Password",
      toggleVisibility: toggleConfirmPasswordVisibility,
      hide: hideConfirmPassword,
      name: "confirmpassword",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/user/resetPassword/` + reset, {
        newPassword: formData.password,
        confirmPassword: formData.confirmpassword,
      })
      .then((res) => {
        toast.success("Reset Password Successfully!");
        navigate("/login");
      })
      .catch((error) => toast.error(error.response.data.message));
  };

  return (
    <div className="auth-container ">
      <AuthContent />
      <div className="auth-input-container">
        <div className="box">
          <h4 className="auth-title">Reset Password</h4>
          <p className="m-0">Set Your New Password</p>

          <form onSubmit={handleSubmit}>
            {inputFields.map((field, index) => (
              <div
                key={index}
                className={field.toggleVisibility ? "password-input" : ""}
              >
                <input
                  type={field.type}
                  required
                  placeholder={field.placeholder}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
                {field.toggleVisibility && (
                  <button type="button" onClick={field.toggleVisibility}>
                    {field.hide ? (
                      <FaEyeSlash className="eye-icon" />
                    ) : (
                      <FaEye className="eye-icon" />
                    )}
                  </button>
                )}
              </div>
            ))}

            <PrimaryButton className="submit-btn" data="Continue" />
          </form>

          <PagePurpose data="Or New Password with" />
          <AuthSocialMediaLinks />
          <SuggesLink
            data="Already registered? Login"
            path="/login"
            pageName="Login"
          />
        </div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
    </div>
  );
}

export default ResetPassWord;
