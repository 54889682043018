// import React, { useEffect, useState } from "react";
// import "../../styles/adsCard.scss";
// import AdsSubmitButton from "../buttons/AdsSubmitButton";
// import { formatDistanceToNow } from "date-fns";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import NotFound from "../loaders/NotFound";
// import { useNavigate } from "react-router-dom";

// function CardAds({ categoryName }) {
//   const [data, setData] = useState([]);
//   const navigate = useNavigate();

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const location = data.PostDetails?.Location?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   useEffect(() => {
//     if (categoryName) {
//       axios
//         .get(`${API_BASE_URL}/user/getfeaturedpost/${categoryName}`)
//         .then((res) => {
//           // console.log("res", res.data.posts);
//           setData(res.data.posts);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, []);

//   return (
//     <>
//       <div className="row">
//         {data.length === 0 ? (
//           <NotFound />
//         ) : (
//           <>
//             {data?.map((item, index) => (
//               <div className="col-sm-6" key={index}>
//                 <div className="card-wrapper">
//                   <div className="card mb-3 h-100">
//                     <div className="row g-0" >
//                       <div className="col-lg-4" >
//                         <img
//                           src={item.PosterImage}
//                           className="img-fluid rounded-start card-image"
//                           alt="ads-image"
//                           onClick={() => handleSingleProduct(item)}
//                         />
//                       </div>
//                       <div className="col-lg-8">
//                         <div className="card-body">
//                           <h5
//                             className="card-title"
//                             onClick={() => handleSingleProduct(item)}
//                           >
//                             {item.postTittle}
//                           </h5>
//                           <div className="card-spaks">
//                             {item.Descriptions?.slice(0, 3).map((item, i) => (
//                               <div key={i}>
//                                 <label htmlFor="">{item.name}</label>
//                                 <p className="m-0">{item.value}</p>
//                               </div>
//                             ))}
//                           </div>

//                           <p className="card-text m-0 py-2">
//                             <small className="text-body-secondary">
//                               {item.postDescription}
//                             </small>
//                           </p>
//                         </div>
//                         <div className="card-footer">
//                           <p className="m-0 ">
//                             <span className="mx-1">Posted:</span>
//                             {formatDistanceToNow(new Date(item.createdAt), {
//                               addSuffix: true,
//                             })}
//                             <span className="mr-1">by a company</span>
//                           </p>

//                           <div>
//                             <AdsSubmitButton item={item} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </>
//         )}
//       </div>
//     </>
//   );
// }

// export default CardAds;

import React, { useEffect, useState } from "react";
import "../../styles/adsCard.scss";
import AdsSubmitButton from "../buttons/AdsSubmitButton";
import { formatDistanceToNow } from "date-fns";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import NotFound from "../loaders/NotFound";
import { useNavigate } from "react-router-dom";

function CardAds({ categoryName }) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  // console.log("category Name in cards add in index page",categoryName)
  const handleSingleProduct = (data) => {
    const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const location = data.PostDetails?.Location?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/${category}/${subCategory}/${adsSlug}`);
  };

  useEffect(() => {
    if (categoryName) {
      axios
        .get(`${API_BASE_URL}/user/getfeaturedpost/${categoryName}`)
        .then((res) => {
          setData(res.data.posts);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [categoryName]);

  return (
    <div className="container">

    <div className="row">
      {data.length === 0 ? (
        <NotFound />
      ) : (
        data?.map((item, index) => (
          <div className="col-sm-6 mb-3" key={index}>
            <div className="card-wrapper">
              <div className="card h-100">
                <div className="row g-0 h-100">
                  <div className="col-lg-4" style={{ display: "flex" }}>
                    <img
                      src={item.PosterImage}
                      className="img-fluid rounded-start card-image"
                      alt="ads-image"
                      onClick={() => handleSingleProduct(item)}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <div className="col-lg-8 d-flex flex-column">
                    <div className="card-body flex-grow-1">
                      <h5
                        className="card-title"
                        onClick={() => handleSingleProduct(item)}
                      >
                        {item.postTittle}
                      </h5>
                      <div className="card-spaks">
                        {item.Descriptions?.slice(0, 3).map((desc, i) => (
                          <div key={i}>
                            <label htmlFor="">{desc.name}</label>
                            <p className="m-0">{desc.value}</p>
                          </div>
                        ))}
                      </div>
                      <p className="card-text m-0 py-2">
                        <span
                          style={{ display: "block", fontWeight: "2px" }}
                          className="mx-1"
                        >
                          Description
                        </span>
                        <small className="text-body-secondary">
                          {item.postDescription}
                        </small>
                      </p>
                    </div>
                    <div className="card-footer">
                      <p className="m-0">
                        <span className="mx-1">Posted:</span>
                        {formatDistanceToNow(new Date(item.createdAt), {
                          addSuffix: true,
                        })}
                        <span className="mr-1"> by a company</span>
                      </p>
                      <AdsSubmitButton item={item} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
    </div>
  );
}

export default CardAds;
