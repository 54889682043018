import React from "react";
import SubTypes from "./SubTypes";

function ApiDropDown({
  descriptions,
  renderInput,
  setSubRolesData,
  subRolesData,
  subRoles,
  subDesriptionName,
}) {
  return (
    <div className="row">
      {descriptions.map((item, index) => (
        <>
          {item.type === "Api Drop Down" && (
            <>
              <div className="col-md-4" key={index}>
                <label className="post-title" htmlFor={item.Description}>
                  {item.Description}
                </label>
                <br />
                {renderInput(item)}
              </div>
            </>
          )}
        </>
      ))}

      <SubTypes
        subRoles={subRoles}
        subDesriptionName={subDesriptionName}
        subRolesData={subRolesData}
        setSubRolesData={setSubRolesData}
      />
    </div>
  );
}

export default ApiDropDown;
