// import React from "react";
// import AdsSubmitButton from "../buttons/AdsSubmitButton";
// import { formatDistanceToNow } from "date-fns";
// import { useNavigate } from "react-router-dom";
// import NotFound from "../loaders/NotFound";
// import CommingSoon from "../loaders/CommingSoon";

// function FilterResults({ categoryName, ads }) {
//   const navigate = useNavigate();

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const location = data.PostDetails?.Location?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   return (
//     <>
//       {ads ? (
//         <>
//           <h3>{categoryName}</h3>

//           <div className="row">
//             {ads.length === 0 ? (
//               <NotFound />
//             ) : (
//               <>
//                 {ads?.map((item, index) => (
//                            <div className="col-sm-6 mb-3" key={index}>
//                            <div className="card-wrapper">
//                              <div className="card h-100">
//                                <div className="row g-0 h-100">
//                                  <div className="col-lg-4">
//                                    <img
//                                      src={item.PosterImage}
//                                      className="img-fluid rounded-start card-image"
//                                      alt="ads-image"
//                                      onClick={() => handleSingleProduct(item)}
//                                      style={{objectFit:"contain"}}
//                                    />
//                                  </div>
//                                  <div className="col-lg-8 d-flex flex-column">
//                                    <div className="card-body flex-grow-1">
//                                      <h5
//                                        className="card-title"
//                                        onClick={() => handleSingleProduct(item)}
//                                      >
//                                        {item.postTittle}
//                                      </h5>
//                                      <div className="card-spaks">
//                                        {item.Descriptions?.slice(0, 3).map((desc, i) => (
//                                          <div key={i}>
//                                            <label htmlFor="">{desc.name}</label>
//                                            <p className="m-0">{desc.value}</p>
//                                          </div>
//                                        ))}
//                                      </div>
//                                      <p className="card-text m-0 py-2">
//                                        <span style={{display:"block",fontWeight:"2px"}} className="mx-1">Description</span>
//                                        <small className="text-body-secondary">
//                                          {item.postDescription}
//                                        </small>
//                                      </p>
//                                    </div>
//                                    <div className="card-footer">
//                                      <p className="m-0">
//                                        <span className="mx-1">Posted:</span>
//                                        {formatDistanceToNow(new Date(item.createdAt), {
//                                          addSuffix: true,
//                                        })}
//                                        <span className="mr-1"> by a company</span>
//                                      </p>

//                               <div>
//                                 {/* <IoIosHeartEmpty className="whislist" /> */}
//                                 <AdsSubmitButton item={item} />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </>
//             )}
//           </div>
//         </>
//       ) : (
//         <>
//           <center className="mt-5">
//             <h1 className="m-0">{categoryName} Ads</h1>
//           </center>
//           <CommingSoon />
//         </>
//       )}
//     </>
//   );
// }

// export default FilterResults;
// import React from "react";
// import AdsSubmitButton from "../buttons/AdsSubmitButton";
// import { formatDistanceToNow } from "date-fns";
// import { useNavigate } from "react-router-dom";
// import NotFound from "../loaders/NotFound";
// import CommingSoon from "../loaders/CommingSoon";
// import SearchInput from "../Inputs/SearchInput";
// import { Col, Row } from "react-bootstrap";
// import { FcSearch } from "react-icons/fc";

// function FilterResults({ subCategoryName,categoryName, ads,count,setDataLoading,setAds,searchQuery,setSearchQuery }) {
//   const navigate = useNavigate();

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(/\s+/g, "-").toLowerCase();
//     const location = data.PostDetails?.Location?.replace(/\s+/g, "-").toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   return (
//     <>
//       {ads ? (
//         <>
//         <Row style={{fontSize:"14px"}}>
//           <Col lg={4} className="d-flex">
          
//        <p >Showing results {count}  for  </p>     <span  className="ms-2 fw-bold mb-1"> {ads[0].PostSubCategory
//         } 
//            { console.log(ads,"sssssssssssssss")}
//           </span> 
//           </Col>
//           <Col lg={8}>
//           {/* <FcSearch  className=""/> */}
//           <SearchInput
//             setSearchQuery={setSearchQuery}
//             searchQuery={searchQuery}
//             ads={ads}
//             setAds={setAds}
//             setDataLoading={setDataLoading}
//             className="w-50 ms-2"
//           /> </Col>

//         </Row>

//           <div className="row">
//             {ads.length === 0 ? (
//               <NotFound />
//             ) : (
//               <>
//                 {ads.map((item, index) => (
//                   <div className="col-sm-12 mb-3" key={index}>
//                     <div className="card-wrapper">
//                       <div className="card h-100">
//                         <div className="row g-0 h-100">
//                           <div className="col-lg-4">
//                             <img
//                               src={item.PosterImage}
//                               className="img-fluid rounded-start card-image"
//                               alt="ads-image"
//                               onClick={() => handleSingleProduct(item)}
//                               style={{ objectFit: "contain" }}
//                             />
//                           </div>
//                           <div className="col-lg-8 d-flex flex-column">
//                             <div className="card-body flex-grow-1">
//                               <h5
//                                 className="card-title"
//                                 onClick={() => handleSingleProduct(item)}
//                               >
//                                 {item.postTittle}
//                               </h5>
//                               <div className="card-spaks">
//                                 {item.Descriptions?.slice(0, 3).map((desc, i) => (
//                                   <div key={i}>
//                                     <label htmlFor="">{desc.name}</label>
//                                     <p className="m-0">{desc.value}</p>
//                                   </div>
//                                 ))}
//                               </div>
//                               <p className="card-text m-0 py-2">
//                                 <span style={{ display: "block", fontWeight: "2px" }} className="mx-1">Description</span>
//                                 <small className="text-body-secondary">
//                                   {item.postDescription}
//                                 </small>
//                               </p>
//                             </div>
//                             <div className="card-footer">
//                               <p className="m-0">
//                                 <span className="mx-1">Posted:</span>
//                                 {formatDistanceToNow(new Date(item.createdAt), {
//                                   addSuffix: true,
//                                 })}
//                                 <span className="mr-1"> by a company</span>
//                               </p>

//                               <div>
//                                 <AdsSubmitButton item={item} />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </>
//             )}
//           </div>
//         </>
//       ) : (
//         <>
//           <center className="mt-5">
//             <h1 className="m-0">{categoryName} Ads</h1>
//           </center>
//           <CommingSoon />
//         </>
//       )}
//     </>
//   );
// }

// export default FilterResults;
// import React, { useState } from "react";
// import AdsSubmitButton from "../buttons/AdsSubmitButton";
// import { formatDistanceToNow } from "date-fns";
// import { useNavigate } from "react-router-dom";
// import NotFound from "../loaders/NotFound";
// import CommingSoon from "../loaders/CommingSoon";
// import SearchInput from "../Inputs/SearchInput";
// import { Col, Row, Dropdown } from "react-bootstrap";

// function FilterResults({
//   subCategoryName,
//   categoryName,
//   ads = [], // Default to an empty array
//   count,
//   setDataLoading,
//   setAds,
//   searchQuery,
//   setSearchQuery,
// }) {
//   const [sortOrder, setSortOrder] = useState("mostRecent");
//   const navigate = useNavigate();

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(/\s+/g, "-").toLowerCase();
//     const location = data.PostDetails?.Location?.replace(/\s+/g, "-").toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   // Ensure ads is an array before sorting
//   const sortedAds = Array.isArray(ads)
//   ? [...ads].sort((a, b) => {
//       const dateA = new Date(a.createdAt);
//       const dateB = new Date(b.createdAt);
//       return sortOrder === "mostRecent"
//         ? dateB - dateA // Most recent first, including date and time
//         : dateA - dateB; // Oldest first, including date and time
//     })
//   : [];


//   // Group ads by PostSubCategory
//   const groupedAds = sortedAds.reduce((acc, ad) => {
//     const category = ad.PostSubCategory || "Unknown";
//     if (!acc[category]) {
//       acc[category] = [];
//     }
//     acc[category].push(ad);
//     return acc;
//   }, {});

//   // Function to format the subcategories
//   const formatSubCategories = (subCategories) => {
//     if (Array.isArray(subCategories)) {
//       return subCategories.join(', ');
//     }
//     return subCategories || "Unknown";
//   };

//   return (
//     <>
//       {Array.isArray(ads) && ads.length > 0 ? (
//         <>
//           <Row style={{ fontSize: "14px" }}>
//             <Col lg={4} className="d-flex align-items-center">
//               <p>Showing results {count} for </p>
//               <span className="ms-2 fw-bold mb-1">
//                 {Object.keys(groupedAds).length === 1
//                   ? formatSubCategories(Object.keys(groupedAds)[0])
//                   : Object.keys(groupedAds).join(', ')}
//               </span>
//             </Col>
//             <Col lg={4} className="d-flex align-items-center">
//               <SearchInput
//                 setSearchQuery={setSearchQuery}
//                 searchQuery={searchQuery}
//                 ads={ads}
//                 setAds={setAds}
//                 setDataLoading={setDataLoading}
//                 className="w-50 ms-2"
//               />
//             </Col>
//             <Col lg={4}>
//               <Dropdown>
//                 <Dropdown.Toggle variant="success" id="dropdown-basic">
//                   Sort By: {sortOrder === "mostRecent" ? "Most Recent" : "Oldest First"}
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                   <Dropdown.Item onClick={() => setSortOrder("mostRecent")}>
//                     Most Recent
    
//                   </Dropdown.Item>
//                   <Dropdown.Item onClick={() => setSortOrder("oldestFirst")}>
//                     Oldest First
          
//                   </Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
//             </Col>
//           </Row>
         

//           <div className="row">
//             {Object.keys(groupedAds).length === 0 ? (
//               <NotFound />
//             ) : (
//               <>
//              {ads.map((item, index) => (
//                   <div className="col-sm-12 mb-3" key={index}>
//                     <div className="card-wrapper">
//                       <div className="card h-100">
//                         <div className="row g-0 h-100">
//                           <div className="col-lg-4">
//                             <img
//                               src={item.PosterImage}
//                               className="img-fluid rounded-start card-image"
//                               alt="ads-image"
//                               onClick={() => handleSingleProduct(item)}
//                               style={{ objectFit: "contain" }}
//                             />
//                           </div>
//                           <div className="col-lg-8 d-flex flex-column">
//                             <div className="card-body flex-grow-1">
//                               <h5
//                                 className="card-title"
//                                 onClick={() => handleSingleProduct(item)}
//                               >
//                                 {item.postTittle}
//                               </h5>
//                               <div className="card-spaks">
//                                 {item.Descriptions?.slice(0, 3).map((desc, i) => (
//                                   <div key={i}>
//                                     <label htmlFor="">{desc.name}</label>
//                                     <p className="m-0">{desc.value}</p>
//                                   </div>
//                                 ))}
//                               </div>
//                               <p className="card-text m-0 py-2">
//                                 <span style={{ display: "block", fontWeight: "2px" }} className="mx-1">Description</span>
//                                 <small className="text-body-secondary">
//                                   {item.postDescription}
//                                 </small>
//                               </p>
//                             </div>
//                             <div className="card-footer">
//                               <p className="m-0">
//                                 <span className="mx-1">Posted:</span>
//                                 {formatDistanceToNow(new Date(item.createdAt), {
//                                   addSuffix: true,
//                                 })}
//                                 <span className="mr-1"> by a company</span>
//                               </p>

//                               <div>
//                                 <AdsSubmitButton item={item} />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </>
//             )}
//           </div>
//               </>
         
      
//       ) : (
//         <>
//           <center className="mt-5">
//             <h1 className="m-0">{categoryName} Ads</h1>
//           </center>
//           <CommingSoon />
//         </>
//       )}
//     </>
//   );
// }

// export default FilterResults;

import React, { useState } from "react";
import AdsSubmitButton from "../buttons/AdsSubmitButton";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import NotFound from "../loaders/NotFound";
import CommingSoon from "../loaders/CommingSoon";
import SearchInput from "../Inputs/SearchInput";
import { Col, Row, Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

function FilterResults({
  subCategoryName,
  categoryName,
  ads = [], // Default to an empty array
  count,
  setDataLoading,
  setAds,
  searchQuery,
  setSearchQuery,
}) {
  const [sortOrder, setSortOrder] = useState("mostRecent");
  const navigate = useNavigate();

  const handleSingleProduct = (data) => {
    const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory?.replace(/\s+/g, "-").toLowerCase();
    const location = data.PostDetails?.Location?.replace(/\s+/g, "-").toLowerCase();
    const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
  };

  // Ensure ads is an array before sorting
  const sortedAds = Array.isArray(ads)
    ? [...ads].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === "mostRecent"
          ? dateB - dateA // Most recent first
          : dateA - dateB; // Oldest first
      })
    : [];

  // Group ads by PostSubCategory
  const groupedAds = sortedAds.reduce((acc, ad) => {
    const category = ad.PostSubCategory || "Unknown";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(ad);
    return acc;
  }, {});

  // Function to format the subcategories
  const formatSubCategories = (subCategories) => {
    if (Array.isArray(subCategories)) {
      return subCategories.join(', ');
    }
    return subCategories || "Unknown";
  };

  return (
    <>
<Helmet>
  <title>
  {Object.keys(groupedAds).length === 1
                  ? formatSubCategories(Object.keys(groupedAds)[0])
                  : Object.keys(groupedAds).join(' - ')}
  </title>
</Helmet>

      {Array.isArray(ads) && ads.length > 0 ? (
        <>
          <Row style={{ fontSize: "14px" }}>
            <Col lg={4} className="d-flex align-items-center" style={{marginTop:"12px"}}>
              <p>Showing results {count} for </p>
              <span className="ms-2 fw-bold mb-3">
                {Object.keys(groupedAds).length === 1
                  ? formatSubCategories(Object.keys(groupedAds)[0])
                  : Object.keys(groupedAds).join(', ')}
              </span>
            </Col>
            <Col lg={6} className=" align-items-center">
              <SearchInput
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                ads={ads}
                setAds={setAds}
                setDataLoading={setDataLoading}
                className=" ms-2"
              />
            </Col>
            <Col lg={2}>
              <Dropdown>
             
                <Dropdown.Toggle variant="info " id="dropdown-basic " className="text-white fw-bold" style={{fontSize:"12px"}}>
                  {sortOrder === "mostRecent" ? "Most Recent" : "Oldest First"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSortOrder("mostRecent")}>
                    Most Recent
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortOrder("oldestFirst")}>
                    Oldest First
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="row">
            {sortedAds.length === 0 ? (
              <NotFound />
            ) : (
              <>
                {sortedAds.map((item, index) => (
                  <div className="col-sm-12 mb-3" key={index}>
                    <div className="card-wrapper">
                      <div className="card h-100">
                        <div className="row g-0 h-100">
                          <div className="col-lg-4">
                            <img
                              src={item.PosterImage}
                              className="img-fluid rounded-start card-image"
                              alt="ads-image"
                              onClick={() => handleSingleProduct(item)}
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <div className="col-lg-8 d-flex flex-column">
                            <div className="card-body flex-grow-1">
                              <h5
                                className="card-title"
                                onClick={() => handleSingleProduct(item)}
                              >
                                {item.postTittle}
                              </h5>
                              <div className="card-spaks">
                                {item.Descriptions?.slice(0, 3).map((desc, i) => (
                                  <div key={i}>
                                    <label htmlFor="">{desc.name}</label>
                                    <p className="m-0">{desc.value}</p>
                                  </div>
                                ))}
                              </div>
                              <p className="card-text m-0 py-2">
                                <span style={{ display: "block", fontWeight: "2px" }} className="mx-1">Description</span>
                                <small className="text-body-secondary">
                                  {item.postDescription}
                                </small>
                              </p>
                            </div>
                            <div className="card-footer">
                              <p className="m-0">
                                <span className="mx-1">Posted:</span>
                                {formatDistanceToNow(new Date(item.createdAt), {
                                  addSuffix: true,
                                })}
                                <span className="mr-1"> by a company</span>
                              </p>

                              <div>
                                <AdsSubmitButton item={item} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <center className="mt-5">
            <h1 className="m-0">{categoryName} Ads</h1>
          </center>
          <CommingSoon />
        </>
      )}
    </>
  );
}

export default FilterResults;
