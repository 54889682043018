import React from "react";
import { AuthContent, SuggesLink } from "../../components";
import success_image from "../../assets/images/success_image.jpg";

function SuccessPage() {
  return (
    <div className="auth-container">
      <AuthContent />
      <div className="auth-input-container">
        <div className="box">
          <center>
            <img
              src={success_image}
              alt="success_image"
              width={200}
              height={200}
            />
            <h4>Reset Password Page Link Sent Your Mail</h4>
          </center>

          <SuggesLink data="Back to Login" path="/login" pageName="Login" />
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
