import React from "react";

function PostDetailsInputs({ setFormData, formData }) {
  const post_details = [
    {
      type: "email",
      id: "email",
      placeholder: "Enter Your Email",
      label: "Email",
      name: "email",
    },
    {
      type: "text",
      id: "mobile",
      placeholder: "Enter Your Mobile Number",
      label: "Mobile Number",
      name: "mobile",
    },
    {
      type: "text",
      id: "company",
      placeholder: "Enter Your Company Name",
      label: "Company Name",
      name: "company",
    },
    {
      type: "text",
      id: "company",
      placeholder: "Enter Your City",
      label: "City",
      name: "city",
    },
    {
      type: "text",
      id: "location",
      placeholder: "Enter Your Location",
      label: "Location",
      name: "location",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="post-details my-4">
      <h5>Post Details</h5> 
      <div className="row">
        {post_details.map((detail, index) => (
          <div className="col-md-4" key={index}>
            <label className="post-title" htmlFor={detail.id}>
              {detail.label}
            </label>
            <br />
            <input
              className="post-input"
              type={detail.type}
              id={detail.id}
              placeholder={detail.placeholder}
              name={detail.name}
              onChange={handleInputChange}
              required
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PostDetailsInputs;
