// import React, { useState } from "react";
// import ApplyModals from "../popupMadals/ApplyModals";

// function AdsSubmitButton({ item, className }) {
//   const [show, setShow] = useState(false);

//   const handleShow = () => setShow(true);

//   return (
//     <>
//       {item && item?.PostCategory?.toLowerCase() === "job" ? (
//         <>
//           <button
//             className={`ads-submit-btn ${className}`}
//             onClick={handleShow}
//           >
//             Apply
//           </button>
//           <ApplyModals
//             item={item}
//             show={show}
//             setShow={setShow}
//             handleShow={handleShow}
//           />
//         </>
//       ) : (
//         <>
//           <button
//             className={`ads-submit-btn ${className}`}
//             onClick={handleShow}
//           >
//             Replay
//           </button>
//           <ApplyModals
//             item={item}
//             show={show}
//             setShow={setShow}
//             handleShow={handleShow}
//           />
//         </>
//       )}
//     </>
//   );
// }

// export default AdsSubmitButton;
import React, { useState } from "react";
import Swal from "sweetalert2";
import ApplyModals from "../popupMadals/ApplyModals";

function AdsSubmitButton({ item, className }) {
  const [show, setShow] = useState(false);
  const isLoggedIn= localStorage.getItem("token");
  console.log("login conditoins in  ads buttom",isLoggedIn ? "true":"false")

  const handleShow = () => {
    const isLoggedIn1= localStorage.getItem("token");
    if (isLoggedIn1) {
      
      setShow(true);
    } else {
      Swal.fire({
        title: 'You need to log in',
        text: 'Please log in to apply or replay.',
        icon: 'warning',
        confirmButtonText: 'Log in',
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect to login page or show login modal
          // For example, you might use window.location to redirect
          window.location.href = '/login';
        }
      });
    }
  };

  return (
    <>
      <button
        className={`ads-submit-btn ${className}`}
        onClick={handleShow}
      >
        {item && item?.PostCategory?.toLowerCase() === "job" ? "Apply" : "Replay"}
      </button>
      {isLoggedIn && (
        <ApplyModals
          item={item}
          show={show}
          setShow={setShow}
          handleShow={handleShow}
        />
      )}
    </>
  );
}

export default AdsSubmitButton;
