import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../utils";
import userContext from "../context";
import { Breadcrumb, HeroAds, SearchInput } from "../components";
import Filters from "../components/showAdsComponents/Filters";
import FilterResults from "../components/showAdsComponents/FilterResults";
import AdsFullTime from "../components/adsComponents/ADsFullTime";
import { Col, Row } from "react-bootstrap";

function ShowAds() {
  const { subcategory } = useParams();
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [currentSubCategory, setCurrentSubCategory] = useState("");
  const [ads, setAds] = useState();
  const categoryName = currentSubCategory?.Main_Category?.Main_Category;
  const subCategoryName = currentSubCategory?.SubCategory;
  const { setGetcategory, setGetSubCategory } = useContext(userContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [count , setCount ]=useState()

  setGetcategory(categoryName);
  setGetSubCategory(subCategoryName);
  console.log(subCategoryName, "categorayname ");
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallSubcategory`)
      .then((res) =>{

        console.log(res,"gsafsas")
        setAllSubCategory(res.data.SubCategory)
      }
      )
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    if (allSubCategory) {
      const SubategoryName = subcategory.replace(/-/g, " ");

      const findCategory = allSubCategory.find(
        (cat) => cat.SubCategory.toLowerCase() === SubategoryName.toLowerCase()
      );

      
      setCurrentSubCategory(findCategory);
    }
  }, [subcategory, allSubCategory]);

  useEffect(() => {
    if (currentSubCategory) {
      axios
        .get(
          `${API_BASE_URL}/user/getalladspost/${categoryName}/${subCategoryName}`
        )
        .then((res) => {
          setCount(res.data.count)
          setAds(res.data.Posts);
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentSubCategory, dataLoading]);

  const breadcrumbItems = [
    { path: "/", label: categoryName },
    { path: "/", label: subCategoryName },
  ];

  return (
    <>
      {/* <div className="container"> */}

      {/* <Breadcrumb items={breadcrumbItems} /> */}
      {/* </div> */}

      {/* <div className="row mx-2 my-4">
        <div className="col-lg-3" >  
          <Filters currentSubCategory={currentSubCategory} setAds={setAds} /> 
          
        </div>

        <div className="filter-results  col-lg-9">
         <FilterResults ads={ads} categoryName={categoryName}     subCategoryName={subCategoryName}/>
        </div>
      </div> */}

      <div className="container">
        <Row>
          <Col md={12}>
            <AdsFullTime />
          </Col>
        </Row>
        <div
          className="ms-auto"
        >
        
          {/* <i className="pi pi-search" style={{ fontSize: "2rem" }}></i> */}
        </div>
        <Row>
          <Col lg={3} className="mt-4">
            <Filters currentSubCategory={currentSubCategory} setAds={setAds} />{" "}
          </Col>

          <Col lg={9}>
            <FilterResults
              ads={ads}
              categoryName={categoryName}
              subCategoryName={subCategoryName}
              count ={count}  
              setDataLoading={setDataLoading}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
             
              setAds={setAds}
              />
              {/* {console.log(count,"count")} */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ShowAds;
