import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import AuthStatus from "./AuthStatus";
import "../../styles/navbar.scss";
import { Link } from "react-router-dom";
import CatAnSubCat from "../popupMadals/CatAnSubCat";
import PostButton from "../buttons/PostButton";
import Logo from '../../assets/images/logo192.jpg'

function Index() {
  const [lgShow, setLgShow] = useState(false);

  return (
    <div>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container>
            <Navbar.Brand as={Link} className="text-light" to="/">
              {/* Logo Here */}
              <img src={Logo}  width="50px" height="50px" style={{borderRadius:"50%"}}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {/* Logo Here */}
                  <img src={Logo}  width="50px" height="50px" style={{borderRadius:"50%"}}/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <AuthStatus />
                  
                  <PostButton setLgShow={setLgShow} /> 

                  <CatAnSubCat lgShow={lgShow} setLgShow={setLgShow} />
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default Index;
