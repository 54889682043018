import React from "react";

function PagePurpose({ data }) {
  return (
    <div className="page-purpose">
      <span>{data}</span>
    </div>
  );
}

export default PagePurpose;
