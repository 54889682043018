import React from "react";
import Lottie from "react-lottie";
import not_found from "../lottie/Not_Found.json";

function NotFound() {
  return (
    <div className="animation">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: not_found,
          speed: 10,
        }}
        height={350}
        width={350}
      />
    </div>
  );
}

export default NotFound;
