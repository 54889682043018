import React, { useEffect, useState } from "react";
import "../styles/postAds.scss";
import PostDetailsInputs from "../components/postAdsComponents/PostDetailsInputs";
import PostStaticInputs from "../components/postAdsComponents/PostStaticInputs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import PostDynamicInputs from "../components/postAdsComponents/PostDynamicInputs";
import SubmitButton from "../components/buttons/SubmitButton";
import { useAuthHeaders } from "../components/Token";
import { useSnackbar } from "notistack";
import PostSubCategory from "../components/postAdsComponents/PostSubCategory";

function AdsPostPage() {
  const [subcategory, setSubcategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const categoryName = selectedSubCategory?.Main_Category?.Main_Category;
  const [descriptions, setDesription] = useState([]);
  const [descriptionsShow, setDescriptionsShow] = useState(false);
  const [subDesriptionName, setSubDesriptionName] = useState("");
  const { slug } = useParams();
  const headers = useAuthHeaders();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallSubcategory`)
      .then((res) => setSubcategory(res.data.SubCategory))
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    const subCategoryName = slug.replace(/-/g, " ");

    const findSubCategory = subcategory.find(
      (sub) => sub.SubCategory.toLowerCase() === subCategoryName.toLowerCase()
    );

    setSelectedSubCategory(findSubCategory);
  }, [slug, subcategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      axios
        .get(
          `${API_BASE_URL}/user/getdescriptionbysubcategory/${selectedSubCategory._id}`
        )
        .then((res) => {
          setDesription(res.data.descriptions);
          setDescriptionsShow(true);
        })
        .catch((error) => {
          console.log("error", error);
          setDescriptionsShow(false);
        });
    }
  }, [selectedSubCategory]);

  const [postTitle, setPostTitle] = useState("");
  const [file, setFile] = useState(null);
  const [postDesription, setPostDesription] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    company: "",
    location: "",
  });

  const [formDataDescription, setFormDataDescription] = useState([]);

  const [subRolesData, setSubRolesData] = useState([
    { name: subDesriptionName, value: [] },
  ]);
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const PostFormData = new FormData();

    PostFormData.append("PosterImage", file);

    PostFormData.append("postTittle", postTitle);
    PostFormData.append("postDescription", postDesription);
    PostFormData.append("PostCategory", categoryName);

    PostFormData.append("PostDetails.name", formData.company);
    PostFormData.append("PostDetails.Email", formData.email);
    PostFormData.append("PostDetails.PhoneNumber", formData.mobile);
    PostFormData.append("PostDetails.City", formData.city);
    PostFormData.append("PostDetails.Location", formData.location);
    PostFormData.append("PostSubCategory", selectedSubCategory.SubCategory);

    subRolesData.forEach((item, index) => {
      PostFormData.append(`DescriptionType.name`, subDesriptionName);
      item.value.forEach((valueItem, valueIndex) => {
        PostFormData.append(`DescriptionType.value[${valueIndex}]`, valueItem);
      });
    });

    formDataDescription.forEach((item, index) => {
      PostFormData.append(`Descriptions[${index}][name]`, item.name);
      PostFormData.append(`Descriptions[${index}][value]`, item.value);
    });

    axios
      .post(`${API_BASE_URL}/postnewads`, PostFormData, { headers })
      .then((res) => {
        enqueueSnackbar("Ads Post Successfully!", { variant: "success" });
        navigate("/");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="container my-4">
      <h3>Post a {categoryName}</h3>
      <form onSubmit={handleSubmit}>
        
        <PostSubCategory
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          categoryName={categoryName}
        />

        <PostStaticInputs
          setPostTitle={setPostTitle}
          setFile={setFile}
          setPostDesription={setPostDesription}
          categoryName={categoryName}
        />

        <PostDynamicInputs
          descriptions={descriptions}
          selectedSubCategory={selectedSubCategory}
          setFormDataDescription={setFormDataDescription}
          subRolesData={subRolesData}
          setSubRolesData={setSubRolesData}
          setSubDesriptionName={setSubDesriptionName}
          subDesriptionName={subDesriptionName}
          descriptionsShow={descriptionsShow}
        />

        <PostDetailsInputs setFormData={setFormData} formData={formData} />

        <SubmitButton data="Post" />
      </form>
    </div>
  );
}

export default AdsPostPage;
