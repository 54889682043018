import React from "react";

function SubmitButton({ data, onClick }) {
  return (
    <button onClick={onClick} className="button-82-pushable" role="button">
      <span className="button-82-shadow"></span>
      <span className="button-82-edge"></span>
      <span className="button-82-front text">{data}</span>
    </button>
  );
}

export default SubmitButton;
