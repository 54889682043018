// import React, { useState } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Layout from "./Layout";
// import Home from "./pages/Home";
// import Login from "./pages/authPages/Login";
// import Register from "./pages/authPages/Register";
// import ForgetPassword from "./pages/authPages/ForgetPassword";
// import ResetPassWord from "./pages/authPages/ResetPassWord";
// import SuccessPage from "./pages/authPages/SuccessPage";
// import Profile from "./pages/authPages/Profile";
// import AdsPostPage from "./pages/AdsPostPage";
// import { AuthProvider } from "./components/Token";
// import ScroolTop from "./components/ScroolTop";
// import ShowAds from "./pages/ShowAds";
// import userContext from "./context";
// import AdsDetail from "./pages/AdsDetail";
// import SearchResults from "./pages/SearchResults";
// import ServiceReportForm from "./components/buttons/ServiceReportForm";
// import Otp from "./pages/authPages/Otp";


// function App() {
//   const [profileActive, setProfileActive] = useState(false);
//   const [getCategory, setGetcategory] = useState("");
//   const [getSubCategory, setGetSubCategory] = useState("");

//   const defaultValue = {
//     profileActive,
//     setProfileActive,
//     getCategory,
//     setGetcategory,
//     getSubCategory,
//     setGetSubCategory,
//   };

//   return (
//     <userContext.Provider value={defaultValue}>
//       <AuthProvider>
//         <BrowserRouter>
//           <Routes>
//               <Route path="/otp"Component={Otp}/>
//             <Route path="/login" Component={Login} />
//             <Route path="/register" element={<Register />} />
//             <Route path=" " Component={Register} />
//             <Route path="/forget-password" Component={ForgetPassword} />
//             <Route path="/reset-password/:reset" Component={ResetPassWord} />
//             <Route path="/success-page" Component={SuccessPage} />
//             <Route path="/asian-form" element={<ServiceReportForm />} />
//             <Route path="/" Component={Layout}>
//               <Route index Component={Home} />
//               <Route path="/profile" Component={Profile} />
//               <Route path="/form/:slug" Component={AdsPostPage} />
//               <Route path="/:category/:subcategory" Component={ShowAds} />
//               <Route
//                 path="/:category/:subcategory/:location/:adsSlug"
//                 element={<AdsDetail />}
//               />
//               <Route
//                 path="/:category/:location/:keyword"
//                 element={<SearchResults />}
//               />

//             </Route>
//           </Routes>
//           <ScroolTop />
//         </BrowserRouter>
//       </AuthProvider>
//     </userContext.Provider>
//   );
// }

// export default App;

// Current++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home";
import Login from "./pages/authPages/Login";
import Register from "./pages/authPages/Register";
import ForgetPassword from "./pages/authPages/ForgetPassword";
import ResetPassWord from "./pages/authPages/ResetPassWord";
import SuccessPage from "./pages/authPages/SuccessPage";
import Profile from "./pages/authPages/Profile";
import AdsPostPage from "./pages/AdsPostPage";
import { AuthProvider } from "./components/Token";
import ScroolTop from "./components/ScroolTop";
import ShowAds from "./pages/ShowAds";
import userContext from "./context";
import AdsDetail from "./pages/AdsDetail";
import SearchResults from "./pages/SearchResults";
import ServiceReportForm from "./components/buttons/ServiceReportForm";
// import "./components/breadcrumb/Breadcrumbs.css"; // Import the Breadcrumbs CSS
import MainCategory from "./components/category/Category";
import Otp from "./pages/authPages/Otp";


function App() {
  const [profileActive, setProfileActive] = useState(false);
  const [getCategory, setGetcategory] = useState("");
  const [getSubCategory, setGetSubCategory] = useState("");
  const [getCategoryId, setCategoryId] = useState("");

  const defaultValue = {
    profileActive,
    setProfileActive,
    getCategory,
    setGetcategory,
    getSubCategory,
    setGetSubCategory,
    getCategoryId,
    setCategoryId,
  };

  return (
    <userContext.Provider value={defaultValue}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/otp"Component={Otp}/>
            <Route path="/login" Component={Login} />
            <Route path="/register" Component={Register} />
            <Route path="/forget-password" Component={ForgetPassword} />
            <Route path="/reset-password/:reset" Component={ResetPassWord} />
            <Route path="/success-page" Component={SuccessPage} />
            {/* <Route path="/asian-form" element={<ServiceReportForm />} /> */}
            <Route path="/" Component={Layout}>
              <Route index Component={Home} />
              <Route path="/profile" Component={Profile} />
              {/* <Route path="/form/:slug" Component={AdsPostPage} /> */}
              {/* <Route path="/:slug/:slug/form" Component={AdsPostPage} /> */}
              <Route path="/:slug/:slug/form" Component={AdsPostPage} />
              <Route path="/:category/:subcategory" Component={ShowAds} />
              {/* <Route path="/:subcategory" Component={ShowAds} /> */}
              <Route
                path="/:category/:subcategory/:location/:adsSlug"
                element={<AdsDetail />}
              />

              <Route path="/:category" element={<MainCategory />} />

              <Route
                path="/:category/:location/:keyword"
                element={<SearchResults />}
              />
              <Route path="/:category/:keyword" element={<SearchResults />} />
              <Route path="/:category/:location" element={<SearchResults />} />
            </Route>
          </Routes>
          <ScroolTop />
        </BrowserRouter>
      </AuthProvider>
    </userContext.Provider>
  );
}

export default App;

// import React, { useState } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Layout from "./Layout";
// import Home from "./pages/Home";
// import Login from "./pages/authPages/Login";
// import Register from "./pages/authPages/Register";
// import ForgetPassword from "./pages/authPages/ForgetPassword";
// import ResetPassWord from "./pages/authPages/ResetPassWord";
// import SuccessPage from "./pages/authPages/SuccessPage";
// import Profile from "./pages/authPages/Profile";
// import AdsPostPage from "./pages/AdsPostPage";
// import { AuthProvider } from "./components/Token";
// import ScroolTop from "./components/ScroolTop";
// import ShowAds from "./pages/ShowAds";
// import userContext from "./context";
// import AdsDetail from "./pages/AdsDetail";
// import SearchResults from "./pages/SearchResults";
// import ServiceReportForm from "./components/buttons/ServiceReportForm";
// import "./components/breadcrumb/Breadcrumbs.css"; // Import the Breadcrumbs CSS
// import MainCategory from "./components/category/Category";

// function App() {
//   const [profileActive, setProfileActive] = useState(false);
//   const [getCategory, setGetcategory] = useState("");
//   const [getSubCategory, setGetSubCategory] = useState("");
//   const [getCategoryId, setCategoryId] = useState("");

//   const defaultValue = {
//     profileActive,
//     setProfileActive,
//     getCategory,
//     setGetcategory,
//     getSubCategory,
//     setGetSubCategory,
//     getCategoryId,
//     setCategoryId,
//   };

//   return (
//     <userContext.Provider value={defaultValue}>
//       <AuthProvider>
//         <BrowserRouter>
//           {/* <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="/register" element={<Register />} />
//             <Route path="/forget-password" element={<ForgetPassword />} />
//             <Route path="/reset-password/:reset" element={<ResetPassWord />} />
//             <Route path="/success-page" element={<SuccessPage />} />
//             <Route path="/asian-form" element={<ServiceReportForm />} />
//             <Route path="/" element={<Layout />}>
//               <Route index element={<Home />} />
//               <Route path="/profile" element={<Profile />} />
//               <Route path="/:slug/:slug/form" element={<AdsPostPage />} />
//               <Route path="/:category/:subcategory" element={<ShowAds />} />
//               <Route path="/:category/:subcategory/:location/:adsSlug" element={<AdsDetail />} />
//               <Route path="/:category/:location/:keyword" element={<SearchResults />} />
//               <Route path="/:category/:keyword" element={<SearchResults />} />
//               <Route path="/:category/:location" element={<SearchResults />} />
//               <Route path="/:category" element={<MainCategory />} />
//             </Route>
//           </Routes> */}

//           <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="/register" element={<Register />} />
//             <Route path="/forget-password" element={<ForgetPassword />} />
//             <Route path="/reset-password/:reset" element={<ResetPassWord />} />
//             <Route path="/success-page" element={<SuccessPage />} />
//             <Route path="/asian-form" element={<ServiceReportForm />} />
//             <Route path="/" element={<Layout />}>
//               <Route index element={<Home />} />
//               <Route path="/profile" element={<Profile />} />
//               <Route path="/:slug/:slug/form" element={<AdsPostPage />} />
//               <Route
//                 path="/:category/:location/:keyword"
//                 element={<SearchResults />}
//               />
//               <Route path="/:category/:keyword" element={<SearchResults />} />
//               <Route path="/:category/:location" element={<SearchResults />} />
//               <Route
//                 path="/:category/:subcategory/:location/:adsSlug"
//                 element={<AdsDetail />}
//               />
//               <Route path="/:category/:subcategory" element={<ShowAds />} />
//               <Route path="/:category" element={<MainCategory />} />
//             </Route>
//           </Routes>
//           <ScroolTop />
//         </BrowserRouter>
//       </AuthProvider>
//     </userContext.Provider>
//   );
// }

// export default App;
