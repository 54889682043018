import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils";
import CommonDesription from "./CommonDesription";
import ApiDropDown from "./ApiDropDown";

function PostDynamicInputs({
  descriptions,
  selectedSubCategory,
  setFormDataDescription,
  setSubRolesData,
  subRolesData,
  setSubDesriptionName,
  subDesriptionName,
  descriptionsShow,
}) {
  const subCategoryId = selectedSubCategory?._id;

  const [roles, setRoles] = useState([]);
  const [subRoles, setSubRoles] = useState([]);

  useEffect(() => {
    if (subCategoryId) {
      axios
        .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
        .then((res) => {
          setRoles(res.data.JobRoles);
          setSubDesriptionName(res.data.description);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [subCategoryId]);

  const handleInputChange = (e, type) => {
    const { value, name } = e.target;

    setFormDataDescription((prevData) => {
      const updatedData = [...prevData];
      const existingIndex = updatedData.findIndex((item) => item.name === name);

      if (existingIndex !== -1) {
        updatedData[existingIndex].value = value;
      } else {
        updatedData.push({ name, value });
      }

      return updatedData;
    });

    if (type === "Api Drop Down") {
      axios
        .get(`${API_BASE_URL}/admin/getjobrolebyjobsubrole/?jobRole=${value}`)
        .then((res) => {
          setSubRoles(res.data.JobSubroles);
          // console.log("sub rouls", res.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const renderInput = (item) => {
    switch (item.type) {
      case "Manual":
        return (
          <input
            className="post-input"
            type="text"
            id={item.Description}
            name={item.Description}
            placeholder={item.Description}
            onChange={(e) => handleInputChange(e)}
          />
        );

      case "Drop Down":
        return (
          <Form.Select
            aria-label="Default select example"
            className="select-input"
            id={item.Description}
            name={item.Description}
            onChange={(e) => handleInputChange(e)}
          >
            <option>Open this {item.Description}</option>
            {item?.DescriptionTypes.map((values, i) => (
              <option key={i} value={values}>
                {values}
              </option>
            ))}
          </Form.Select>
        );

      case "Api Drop Down":
        return (
          <Form.Select
            aria-label="Default select example"
            className="select-input"
            id={item.Description}
            name={item.Description}
            onChange={(e) => handleInputChange(e, item.type)}
          >
            <option>Open this {item.Description}</option>
            {roles.map((role, i) => (
              <option key={i} value={role}>
                {role}
              </option>
            ))}
          </Form.Select>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {descriptionsShow === true && (
        <>
          <ApiDropDown
            descriptions={descriptions}
            renderInput={renderInput}
            setSubRolesData={setSubRolesData}
            subRolesData={subRolesData}
            subRoles={subRoles}
            subDesriptionName={subDesriptionName}
          />
          
          <CommonDesription
            descriptions={descriptions}
            renderInput={renderInput}
          />
        </>
      )}
    </>
  );
}

export default PostDynamicInputs;
