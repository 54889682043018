// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { API_BASE_URL } from '../../utils';
// import { AuthContent } from '../../components';

// function Otp() {
//   const [otpStatus, setOtpStatus] = useState(''); // State to hold OTP status
//   const [loading, setLoading] = useState(true); // State to handle loading status
//   const [error, setError] = useState(''); // State to hold error messages

//   useEffect(() => {
//     const verifyOtp = async () => {
//       try {
//         setLoading(true); // Set loading to true before making the request
//         const response = await axios.post(`${API_BASE_URL}/user/otpverification`);
//         console.log('OTP verification response:', response.data);
//         setOtpStatus('OTP verified successfully!')
//       } catch (error) {
//         console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
//         setError('Failed to verify OTP. Please try again.')
//       } finally {
//         setLoading(false);
//       }
//     };

//     verifyOtp();
//   }, []); // Empty dependency array ensures this runs only once

//   return (
//     <div>
//       {/* {loading && <p>Loading...</p>} {/* Display loading message */}
//       {/* {otpStatus && <p>{otpStatus}</p>} Display OTP verification status */}
//       {/* {error && <p>{error}</p>} Display error message */}
//       <div className="auth-container">
//       <AuthContent />
//       <div className="auth-input-container">
//         <div className="box">
//           <h4 className="auth-title">Log in</h4>
//           <p className="m-0">
//             Enter your email and password to login to your account.
//           </p>

//           <form onSubmit={handleSubmit}>
//             {inputFields.map((field, index) => (
//               <div
//                 key={index}
//                 className={field.toggleVisibility ? "password-input" : ""}
//               >
//                 <input
//                   type={field.type}
//                   required
//                   placeholder={field.placeholder}
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleInputChange}
//                 />
//                 {field.toggleVisibility && (
//                   <button type="button" onClick={field.toggleVisibility}>
//                     {field.hide ? (
//                              <FaEye className="eye-icon" />
//                     ) : (
//                       <FaEyeSlash className="eye-icon" />

//                     )}
//                   </button>
//                 )}
//               </div>
//             ))}

//             <div className="options">
//               <div className="d-flex my-2">
//                 <input type="checkbox" name="" id="Remember" />
//                 <label htmlFor="Remember">Remember me</label>
//               </div>
//               <Link to="/forget-password">Forget password?</Link>
//             </div>

//             <PrimaryButton className="submit-btn" data="Log in" />
//           </form>

//           <PagePurpose data="Or sign in with" />
//           <AuthSocialMediaLinks />
//           <SuggesLink
//             data="Don’t have an account?"
//             path="/register"
//             pageName="Create now"
//           />
//         </div>
//       </div>
//       <Toaster position="bottom-center" reverseOrder={false} />
//     </div>
//     </div>
//   );
// }

// export default Otp;
// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { API_BASE_URL } from '../../utils';
// import { AuthContent, PagePurpose, SuggesLink } from '../../components';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import toast, { Toaster } from 'react-hot-toast';
// import PrimaryButton from '../../components/buttons/PrimaryButton';
// import AuthSocialMediaLinks from '../../components/authComponents/AuthSocialMediaLinks';

// function Otp() {
//   const [otpStatus, setOtpStatus] = useState(''); // State to hold OTP status
//   const [loading, setLoading] = useState(true); // State to handle loading status
//   const [error, setError] = useState(''); // State to hold error messages
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [hidePassword, setHidePassword] = useState(false);

//   useEffect(() => {
//     const verifyOtp = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.post(`${API_BASE_URL}/user/otpverification`);
//         console.log('OTP verification response:', response.data);
//         setOtpStatus('OTP verified successfully!');
//       } catch (error) {
//         console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
//         setError('Failed to verify OTP. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     verifyOtp();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const togglePasswordVisibility = () => {
//     setHidePassword(!hidePassword);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Add your form submission logic here
//   };

//   const inputFields = [
//     { type: "text", placeholder: "Email address", name: "email" },
//     {
//       type: hidePassword ? "text" : "password",
//       placeholder: "8+ strong password",
//       name: "password",
//       toggleVisibility: togglePasswordVisibility,
//       hide: hidePassword,
//     },
//   ];

//   return (
//     <div className="auth-container">
//       <AuthContent />
//       <div className="auth-input-container">
//         <div className="box">
//           <h4 className="auth-title"></h4>
//           <p className="m-0">
//             Enter your email and password to login to your account.
//           </p>

//           {loading && <p>Loading...</p>}
//           {otpStatus && <p>{otpStatus}</p>}
//           {error && <p>{error}</p>}

//           <form onSubmit={handleSubmit}>
//             {inputFields.map((field, index) => (
//               <div
//                 key={index}
//                 className={field.toggleVisibility ? "password-input" : ""}
//               >
//                 <input
//                   type={field.type}
//                   required
//                   placeholder={field.placeholder}
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleInputChange}
//                 />
//                 {field.toggleVisibility && (
//                   <button type="button" onClick={field.toggleVisibility}>
//                     {field.hide ? (
//                       <FaEye className="eye-icon" />
//                     ) : (
//                       <FaEyeSlash className="eye-icon" />
//                     )}
//                   </button>
//                 )}
//               </div>
//             ))}

//             <div className="options">
//               <div className="d-flex my-2">
//                 <input type="checkbox" name="" id="Remember" />
//                 <label htmlFor="Remember">Remember me</label>
//               </div>
//               <Link to="/forget-password">Forget password?</Link>
//             </div>

//             <PrimaryButton className="submit-btn" data="Log in" />
//           </form>

//           <PagePurpose data="Or sign in with" />
//           <AuthSocialMediaLinks />
//           <SuggesLink
//             data="Don’t have an account?"
//             path="/register"
//             pageName="Create now"
//           />
//         </div>
//       </div>
//       <Toaster position="bottom-center" reverseOrder={false} />
//     </div>
//   );
// }

// export default Otp;
import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../utils";
import { AuthContent, PagePurpose, SuggesLink } from "../../components";
import { Toaster } from "react-hot-toast";
import "./Otp.scss"; // Assuming you have custom styles for OTP
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AuthSocialMediaLinks from "../../components/authComponents/AuthSocialMediaLinks";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { GoArrowLeft } from "react-icons/go";

function Otp() {
  const [otp, setOtp] = useState(["", "", "", ""]); // State for OTP digits
  const [loading, setLoading] = useState(false); // State to handle loading status
  const [error, setError] = useState(""); // State to hold error messages
  const [success, setSuccess] = useState(""); // State to hold success messages
  const userEmail = sessionStorage?.getItem("userEmail");
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value.slice(0, 1); // Ensure single character input
    setOtp(newOtp);

    // Focus next input field
    if (e.target.value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleResendOTP = () => {
    const savedEmail =
      // localStorage.getItem("userEmail") ||
      sessionStorage.getItem("userEmail");

    // Send OTP request
    axios
      .post(`${API_BASE_URL}/user/resendotp`, { email: savedEmail })
      .then((response) => {
        // console.log("response from user resend OTP", response.data);
        Swal.fire({
          icon: "success",
          title: "OTP send Successfully",
          showConfirmButton: false,
          timer: 3000,
          text: response.data.message,
        });
      });

    // Start the timer
    setIsDisabled(true);
    setTimer(60); // Set timer duration in seconds (e.g., 60 seconds)

    // Set up the interval
    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(id);
          setIsDisabled(false); // Enable the button when the timer ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    setIntervalId(id);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpString = otp.join("");

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/user/otpverification`,
        { otp: otpString }
      );
      console.log("OTP verification response:", response.data);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("token", response.data.token);
      setSuccess("OTP verified successfully!");
      navigate("/");
    } catch (error) {
      console.error(
        "Error verifying OTP:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <div className="auth-container">
      <AuthContent />
      <div className="auth-input-container">
        <div className="box">
          <div className="otp-title" style={{textAlign:"left"}}><span><GoArrowLeft className="cancel-otp-arrow" onClick={handleCancel}/>Enter OTP</span> </div>
          <p className="m-0">Enter the OTP sent to your {userEmail} Account</p>

          <form onSubmit={handleSubmit}>
            <div className="otp-inputs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  className="otp-input"
                />
              ))}
            </div>

            {loading && <p>Loading...</p>}
            {success && <p className="success-message">{success}</p>}
            {error && <p className="error-message">{error}</p>}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                padding: "20px 0px",
              }}
            >
              {/* <button className="cancle-btn w-30" onClick={handleCancel}>
                cancel
              </button> */}
              <PrimaryButton className="submit-btn w-100" data="Verify OTP" />
            </div>
          </form>

          <div className="resendotp">
            <p
              className="m-0"
              style={{ textAlign: "center" }}
            >
              Didn’t receive the OTP?
              <button
                className="resendotp-button mx-1"
                onClick={handleResendOTP}
                disabled={isDisabled}
                style={{
                  color: "blue",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  background: "none", // Remove background color
                  border: "none", // Remove border
                }}
              >
                {isDisabled ? `Resend OTP (${timer}s)` : "Resend OTP"}
              </button>,
            </p>
          </div>

          {/* <span data="" className="mt-3 text-center">
            Or sign in with
        
          </span>
          <div
            className="text-center "
            style={{ justifyContent: "space-between" }}
          >
            <AuthSocialMediaLinks />
          </div> */}
          {/* <SuggesLink
            data="Don’t have an account?"
            path="/register"
            pageName="Create now"
          /> */}
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default Otp;
