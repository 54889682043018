import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import "../../styles/home.scss";

function index() {
  return (
    <div className="container">
      <div className="premium-membership">
        <div>
          <h4 className="membership-title">Get Premium Membership</h4>
          <p className="membership-contant m-0">
            Upgrade to Premium Membership for exclusive benefits, priority
            support, and special discounts. Enjoy an enhanced experience with
            added features. Join now and elevate your membership
          </p>
        </div>
        <div>
          <PrimaryButton data="Get Membership" className="membership-btn" />
        </div>
      </div>
    </div>
  );
}

export default index;
