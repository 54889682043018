import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../Token";
import { formatDistanceToNow } from "date-fns";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNavigate } from "react-router-dom";

function MyAds() {
  const headers = useAuthHeaders();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/youradspost`, { headers })
      .then((res) => {
        // console.log("res", res.data.posts);
        setData(res.data.posts.reverse());
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const adsDetails = (data) => {
    const category = data.PostCategory.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory.replace(/\s+/g, "-").toLowerCase();
    const location = data.PostDetails?.Location.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const slug = data.slug.replace(/\s+/g, "-").toLowerCase();

    navigate(`/${category}/${subCategory}/${location}/${slug}`);
  };

  const handleAdsDelete = (adsId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/deleteadspost/${adsId}`)
          .then((res) => {
            console.log("res", res);
            setData((prv) => prv.filter((ads) => ads._id !== adsId));
            Swal.fire({
              title: "Deleted!",
              text: "Your Ads has been deleted.",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };

  return (
    <div className="my-ads-container">
      <h3 className="profile-title">My Ads</h3>

      {data.map((item, index) => (
        // console.log("item", item)
        <div className="row border-bottom p-2" key={index}>
          <div className="col-md-9">
            <div className="ads-details d-flex justify-content-between">
              <div> 
                <p className="ads-data m-0">{item.postTittle}</p>
                <p className="ads-data m-0">{item.PostCategory}</p>
                <p className="ads-data m-0">{item.PostSubCategory}</p>
              </div>
              <div>
                <p className="m-0" style={{ fontSize: "14px" }}>
                  {formatDistanceToNow(new Date(item.createdAt), {
                    addSuffix: true,
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 actions">
            <h6 className="ads-title">Actions</h6>
            <ul>
              <li onClick={() => adsDetails(item)}>view</li>
              <li onClick={() => handleAdsDelete(item?._id)}>Delete</li>
              <li>Active</li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MyAds;
