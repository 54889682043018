import React from "react";
import "./Preloader.css"
const Preloader = () => {
    
  return (
    <div class="preloader-container">
      <div class="preloader-cards">
        <div class="preload-card">l</div>
        <div class="preload-card">o</div>
        <div class="preload-card">a</div>
        <div class="preload-card">d</div>
        <div class="preload-card">i</div>
        <div class="preload-card">n</div>
        <div class="preload-card">g</div>
      </div>
    </div>
  );
};

export default Preloader;
