import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
} from "mdb-react-ui-kit";

function MyProfile({ profileData }) {
  return (
    <MDBContainer className="py-2 h-100">
      <MDBRow className="justify-content-center align-items-center h-100">
        <MDBCol lg="9" xl="7">
          <MDBCard>
            <div
              className="rounded-top text-white d-flex flex-row"
              style={{ backgroundColor: "#00B9FF", height: "200px" }}
            >
              <div
                className="ms-4 mt-5 d-flex flex-column"
                style={{ width: "150px" }}
              >
                <MDBCardImage
                  src="https://loverary.files.wordpress.com/2013/10/facebook-default-no-profile-pic.jpg?w=1200"
                  alt="Generic placeholder image"
                  className="mt-4 mb-2 img-thumbnail"
                  fluid
                  style={{ width: "150px", zIndex: "1" }}
                />
              </div>
              <div className="ms-3" style={{ marginTop: "130px" }}>
                <MDBTypography tag="h5">
                  {profileData ? (
                    <h3 className="text-light">{profileData.username}</h3>
                  ) : (
                    <p>Loading...</p>
                  )}
                </MDBTypography>
              </div>
            </div>

            <MDBCardBody className="text-black p-4">
              <div>
                <p className="lead fw-normal mb-1">About</p>
                <div className="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                  {profileData ? (
                    <>
                      <MDBCardText className="font-italic mb-1">
                        {profileData.useremail}
                      </MDBCardText>

                      <MDBCardText className="font-italic mb-1">
                        {profileData.usermobile}
                      </MDBCardText>
                    </>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default MyProfile;
