// import React, { useEffect, useState } from "react";
// import "../../styles/home.scss";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import { useNavigate, useParams } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import { Placeholder } from "react-bootstrap";
// import error_image from "../../assets/images/error_images.png";
// import SubmitButton from "../buttons/SubmitButton";
// import "./Category.scss"

// const Category = () => {
//   const { category } = useParams();
//   const [loadMore, setLoadMore] = useState(6);
//   const [show, setShow] = useState(false);
//   const [allCategory, setAllCategory] = useState([]);
//   const [selectedCategoryId, setSelectedCategoryId] = useState("");
//   const [subcategory, setSubCategory] = useState([]);
//   const navigate = useNavigate();
//   const [initialLoad, setInitialLoad] = useState(true);

//   const handleClose = () => setShow(false);

//   const categoryNameParam =
//     category.charAt(0).toUpperCase() + category.slice(1);

//   console.log("param changed Category name", categoryNameParam);

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/getallmaincategory`)
//       .then((response) => {
//         setAllCategory(response.data.mainCategories);
//         response.data.mainCategories.forEach((cat) => {
//           if (categoryNameParam === cat?.Main_Category) {
//             setSelectedCategoryId(cat?._id);
//           }
//         });
//       })
//       .catch((err) => console.log(err));
//   }, [categoryNameParam]);

//   useEffect(() => {
//     if (selectedCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${selectedCategoryId}`)
//         .then((response) => {
//           console.log("selected SubCategory", response.data.Subcategories);
//           setSubCategory(response.data.Subcategories);
//         })
//         .catch((error) => {
//           console.error("Error fetching subcategory data:", error);
//         });
//     }
//   }, [selectedCategoryId]);

//   const loadMoreItems = () => {
//     if (initialLoad) {
//       setLoadMore((prev) => prev + 6);
//       setInitialLoad(false);
//     } else {
//       setLoadMore(6);
//       setInitialLoad(true);
//     }
//   };

//   const handleSelectSubCategory = (subCategory) => {
//     const categorySlug = category;
//     const subCategoryName = subCategory?.SubCategory?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();

//     navigate(`/${categorySlug}/${subCategoryName}`);
//   };

//   console.log("all category is", allCategory);
//   console.log("selected category id is", selectedCategoryId);

//   return (
//     <div className="container" style={{ marginTop: "7%" }}>
//       {subcategory.length > 0 ? (
//         <div className="category-container row  main-category">
//           {subcategory.slice(0, loadMore).map((item, i) => (
//             <div
//               onClick={() => handleSelectSubCategory(item)}
//               className="category col-md-2"
//               key={i}
//             >
//               <h6 className="category-name m-0">{item?.SubCategory}</h6>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="category-container row">
//           {[...Array(6)].map((_, i) => (
//             <div className="category col-md-2" key={i}>
//               <Placeholder as="div" animation="glow">
//                 <img src={error_image} alt="category-icon" />
//               </Placeholder>
//               <Placeholder
//                 as="h6"
//                 animation="glow"
//                 className="category-name m-0"
//               >
//                 <Placeholder xs={6} />
//               </Placeholder>
//               <Placeholder
//                 as="p"
//                 animation="glow"
//                 className="category-text m-0"
//               >
//                 <Placeholder xs={7} /> <Placeholder xs={4} />
//               </Placeholder>
//             </div>
//           ))}
//         </div>
//       )}
//       {subcategory.length > 0 && (
//         <center>
//           <SubmitButton
//             onClick={loadMoreItems}
//             data={initialLoad ? "View More" : "View Less"}
//           />
//         </center>
//       )}

//     </div>
//   );
// };

// export default Category;

import React, { useContext, useEffect, useState } from "react";
import "../../styles/home.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import error_image from "../../assets/images/error_images.png";
import SubmitButton from "../buttons/SubmitButton";
import "./Category.scss";
import HeroAds from "../adsComponents/HeroAds";
import AdsCard from "../../components/adsComponents/AdCard/index";
import CardAds from "../adsComponents/CardAds";
import AdsSubmitButton from "../buttons/AdsSubmitButton";
import NotFound from "../loaders/NotFound";
import { formatDistanceToNow } from "date-fns";
import userContext from "../../context/index";
import Preloader from "../../PreLoader/Preloader";
import { Helmet } from "react-helmet";

const Category = () => {
  const { category } = useParams();
  const [loadMore, setLoadMore] = useState(4);
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subcategory, setSubCategory] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [firstSubcategory,setFirstSubCategory]=useState("")
  const { setGetcategory, setGetSubCategory } = useContext(userContext);
  const categoryNameParam =
    category.charAt(0).toUpperCase() + category.slice(1);

    const[selectedSubcategory,setSelectedSubcateory]=useState("")

  const categoryName = categoryNameParam;
  const subCategoryName = selectedSubcategory;
  setGetcategory(categoryName);
  setGetSubCategory(subCategoryName?subCategoryName:firstSubcategory);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallmaincategory`)
      .then((response) => {
        setAllCategory(response.data.mainCategories);
        response.data.mainCategories.forEach((cat) => {
          if (categoryNameParam === cat?.Main_Category) {
            setSelectedCategoryId(cat?._id);
          }
        });
      })
      .catch((err) => console.log(err));
  }, [categoryNameParam]);

  useEffect(() => {
    if (selectedCategoryId) {
      axios
        .get(`${API_BASE_URL}/getsubcategorybycategory/${selectedCategoryId}`)
        .then((response) => {
          setSubCategory(response.data.Subcategories);
          setFirstSubCategory(response.data.Subcategories[0].SubCategory)
        })
        .catch((error) => {
          console.error("Error fetching subcategory data:", error);
        });
    }
  }, [selectedCategoryId]);

  const loadMoreItems = () => {
    if (loadMore === 4) {
      setLoadMore(subcategory.length);
    } else {
      setLoadMore(4);
    }
  };

  const handleSelectSubCategory = (subCategory) => {
    const categorySlug = category;
    const subCategoryName = subCategory?.SubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
setSelectedSubcateory(subCategoryName)
    navigate(`/${categorySlug}/${subCategoryName}`);
  };
  ///============================================== Category  by posts Get all =============================

  // console.log("category Name in cards add in index page",categoryName)
  const handleSingleProduct = (data) => {
    const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const location = data.PostDetails?.Location?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
  };

  useEffect(() => {
    if (categoryNameParam) {
      axios
        .get(
          `${API_BASE_URL}/user/getadspostbycategory?category=${categoryNameParam}`
        )
        .then((res) => {
          setData(res.data.AdsPost);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [categoryNameParam]);

  return (
    <>


<Helmet>
        <title>{categoryNameParam}</title>
        {/* <meta name="description" content={subCategoryDescriptions} /> */}
        <meta 
  name="description" 
  content={
    subcategory.length > 0 
      ? subcategory.map(item => item.SubCategory).join(', ') 
      : "d"
  } 
/>
      </Helmet>
      <h3 style={{ textAlign: "center" }}>select {categoryNameParam} type</h3>
      <div className="container" style={{ marginTop: "7%" }}>
        {subcategory.length > 0 ? (
          <div className="row main-category">
            {subcategory.slice(0, loadMore).map((item, i) => (
              <div
                onClick={() => handleSelectSubCategory(item)}
                className="category col-md-2"
                key={i}
                // style={{borderRight:"2px solid red"}}
              >
                <h6 className="category-name m-0">{item?.SubCategory}</h6>
              </div>
            ))}
          </div>
        ) : (
          <div className="row main-category">
            {/* <span style={{ color: "red", textAlign: "center" }}>
              loading...
            </span> */}
            <Preloader/>
          </div>
        )}
        {subcategory.length > 4 && (
          <center>
            <SubmitButton
              onClick={loadMoreItems}
              data={loadMore === 4 ? "View More" : "View Less"}
            />
          </center>
        )}
      </div>
      <HeroAds />

      {/* <CardAds categoryName={item?.Main_Category} /> */}
      {/* <CardAds categoryName={categoryNameParam} /> */}
<div className="container">
      <div className="row">
        {data?.length === 0 ? (
          <NotFound />
        ) : (
          data?.map((item, index) => (
            <div className="col-sm-6 mb-3" key={index}>
              <div className="card-wrapper">
                <div className="card h-100">
                  <div className="row g-0 h-100">
                    <div className="col-lg-4">
                      <img
                        src={item.PosterImage}
                        className="img-fluid rounded-start card-image"
                        alt="ads-image"
                        onClick={() => handleSingleProduct(item)}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div className="col-lg-8 d-flex flex-column">
                      <div className="card-body flex-grow-1">
                        <h5
                          className="card-title"
                          onClick={() => handleSingleProduct(item)}
                        >
                          {item.postTittle}
                        </h5>
                        <div className="card-spaks">
                          {item.Descriptions?.slice(0, 3).map((desc, i) => (
                            <div key={i}>
                              <label htmlFor="">{desc.name}</label>
                              <p className="m-0">{desc.value}</p>
                            </div>
                          ))}
                        </div>
                        <p className="card-text m-0 py-2">
                          <span
                            style={{ display: "block", fontWeight: "2px" }}
                            className="mx-1"
                          >
                            Description
                          </span>
                          <small className="text-body-secondary">
                            {item.postDescription}
                          </small>
                        </p>
                      </div>
                      <div className="card-footer">
                        <p className="m-0">
                          <span className="mx-1">Posted:</span>
                          {formatDistanceToNow(new Date(item.createdAt), {
                            addSuffix: true,
                          })}
                          <span className="mr-1"> by a company</span>
                        </p>
                        <AdsSubmitButton item={item} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

</div>
    </>
  );
};

export default Category;
