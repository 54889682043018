// // import React from 'react';
// // import { Link, useLocation } from 'react-router-dom';

// // const Breadcrumbs = () => {
// //   const location = useLocation();
// //   const pathname = location.pathname;
// //   const pathnames = pathname.split('/').filter(x => x);

// //   return (
// //     <nav aria-label="breadcrumb">
// //       <ol className="breadcrumb">
// //         <li className="breadcrumb-item">
// //           <Link to="/">Home</Link>
// //         </li>
// //         {pathnames.map((value, index) => {
// //           const to = `/${pathnames.slice(0, index + 1).join('/')}`;
// //           return (
// //             <li key={to} className="breadcrumb-item">
// //               <Link to={to}>{value}</Link>
// //             </li>
// //           );
// //         })}
// //       </ol>
// //     </nav>
// //   );
// // };

// // export default Breadcrumbs;
















// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Breadcrumbs = () => {
//   const location = useLocation();
//   const pathname = location.pathname;
//   const pathnames = pathname.split('/').filter(x => x);

//   // Determine the maximum depth for breadcrumbs (up to "/:category/")
//   const maxDepth = 2; // Change this to adjust the depth

//   return (
//     <nav aria-label="breadcrumb">
//       <ol className="breadcrumb">
//         <li className="breadcrumb-item">
//           <Link to="/">Home</Link>
//         </li>
//         {pathnames.slice(0, maxDepth).map((value, index) => {
//           const to = `/${pathnames.slice(0, index + 1).join('/')}`;
//           return (
//             <li key={to} className="breadcrumb-item">
//               <Link to={to}>{value}</Link>
//             </li>
//           );
//         })}
//       </ol>
//     </nav>
//   );
// };

// export default Breadcrumbs;



// Updated 26/07/2024
// import React from "react";
// import { Link, useLocation } from "react-router-dom";

// const Breadcrumbs=()=> {
//   const location = useLocation();
//   let crumbLink = "";
//   const crumbPath = location.pathname
//     .split("/")
//     .filter((path) => path !== "")
//     .map((crumb) => {
//       crumbLink += `/${crumb}`
//         return
//       <Link key={crumb} to={crumbLink}>
//         {crumb}
//       </Link>;
//     });
//     console.log(crumbPath)
//   return (

//   <div>
//     <Breadcrumbs aria-label="breadcrumb">
//     {crumbPath}
//     </Breadcrumbs>
//   </div>
//   ) 
// }

// export default Breadcrumbs;
// import React from "react";
// import { Link, useLocation } from "react-router-dom";

// import Typography from '@mui/material/Typography';
// import { Breadcrumbs } from "@mui/material";

// const BreadCrumbs = () => {
//   const location = useLocation();
//   let crumbLink = "";
//   const crumbPath = location.pathname
//     .split("/")
//     .filter((path) => path !== "")
//     .map((crumb, index, array) => {
//       crumbLink += `/${crumb}`;
//       return index === array.length - 1 ? (
//         <Typography key={crumb} color="textPrimary">
//           {crumb}
//         </Typography>
//       ) : (
//         <Link key={crumb} to={crumbLink}>
//           {crumb}
//         </Link>
//       );
//     });
// {console.log(crumbPath)}
//   return (
//     <div>
//       <Breadcrumbs aria-label="breadcrumb">
//         {crumbPath}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;
// import React, { useContext, useEffect, useState } from "react";
// import { Link, useLocation, useParams } from "react-router-dom";
// import Typography from '@mui/material/Typography';
// import { Breadcrumbs } from "@mui/material";
// import { API_BASE_URL } from "../../utils";
// import axios from "axios";
// import userContext from "../../context";

// const BreadCrumbs = () => {
//   const location = useLocation();
   
//   let crumbLink = "";
//   const pathSegments = location.pathname
//     .split("/")
//     .filter((path) => path !== "");
//     const { subcategory } = useParams();
//     const [allSubCategory, setAllSubCategory] = useState([]);
//     const [currentSubCategory, setCurrentSubCategory] = useState("");
//     const [ads, setAds] = useState([]);
//     const categoryName = currentSubCategory?.Main_Category?.Main_Category;
//     const subCategoryName = currentSubCategory?.SubCategory;
//     const { setGetcategory, setGetSubCategory } = useContext(userContext);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [dataLoading, setDataLoading] = useState(false);
  
//     setGetcategory(categoryName);
//     setGetSubCategory(subCategoryName);
  
//     useEffect(() => {
//       axios
//         .get(`${API_BASE_URL}/admin/getallSubcategory`)
//         .then((res) => setAllSubCategory(res.data.SubCategory))
//         .catch((error) => console.log("error", error));
//     }, []);
  
//     useEffect(() => {
//       if (allSubCategory) {
//         const SubategoryName = subcategory.replace(/-/g, " ");
  
//         const findCategory = allSubCategory.find(
//           (cat) => cat.SubCategory.toLowerCase() === SubategoryName.toLowerCase()
//         );
  
//         setCurrentSubCategory(findCategory);
//       }
//     }, [subcategory, allSubCategory]);
  
//     useEffect(() => {
//       if (currentSubCategory) {
//         axios
//           .get(
//             `${API_BASE_URL}/user/getalladspost/${categoryName}/${subCategoryName}`
//           )
//           .then((res) => {
//             console.log(res?.data?.Posts.PostDetails?.Location
//               ,"data from breadcrumbs")
              
//             setAds(res?.data?.Posts[0].PostDetails?.Location);
//           })
//           .catch((error) => console.log("error", error));
//       }
//     }, [currentSubCategory, dataLoading]);

//     const excludeSegments = ads;
//   const filteredSegments = pathSegments.filter(segment => !excludeSegments.includes(segment));

//   const crumbs = filteredSegments.map((crumb, index, array) => {
//     crumbLink += `/${crumb}`;
//     return index === array.length - 1 ? (
//       <Typography key={crumb} color="textPrimary">
//         {crumb}
//       </Typography>
//     ) : (
//       <Link key={crumb} to={crumbLink}>
//         {crumb}
//       </Link>
//     );
//   });

//   return (
//     <div>
//       <Breadcrumbs aria-label="breadcrumb">
//         {crumbs}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;

// import React, { useContext, useEffect, useState } from "react";
// import { Link, useLocation, useParams } from "react-router-dom";
// import Typography from "@mui/material/Typography";
// import { Breadcrumbs } from "@mui/material";
// import axios from "axios";
// import userContext from "../../context";
// import { API_BASE_URL } from "../../utils";

// const BreadCrumbs = () => {
//   const location = useLocation();
//   const { subcategory } = useParams();
//   const [allSubCategory, setAllSubCategory] = useState([]);
//   const [currentSubCategory, setCurrentSubCategory] = useState(null);
//   const [ads, setAds] = useState([]);
//   const { setGetcategory, setGetSubCategory } = useContext(userContext);

//   // Update category and subcategory in context
//   useEffect(() => {
//     if (currentSubCategory) {
//       const categoryName = currentSubCategory.Main_Category?.Main_Category;
//       const subCategoryName = currentSubCategory.SubCategory;
//       setGetcategory(categoryName);
//       setGetSubCategory(subCategoryName);
//     }
//   }, [currentSubCategory, setGetcategory, setGetSubCategory]);

//   // Fetch all subcategories
//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/getallSubcategory`)
//       .then((res) => setAllSubCategory(res.data.SubCategory))
//       .catch((error) => console.log("error", error));
//   }, []);

//   // Find and set the current subcategory based on the URL
//   useEffect(() => {
//     if (subcategory && allSubCategory.length > 0) {
//       const SubategoryName = subcategory.replace(/-/g, " ");
//       const findCategory = allSubCategory.find(
//         (cat) => cat.SubCategory.toLowerCase() === SubategoryName.toLowerCase()
//       );
//       setCurrentSubCategory(findCategory);
//     }
//   }, [subcategory, allSubCategory]);

//   // Fetch ads based on the current subcategory
//   useEffect(() => {
//     if (currentSubCategory) {
//       const categoryName = currentSubCategory.Main_Category?.Main_Category;
//       const subCategoryName = currentSubCategory.SubCategory;
//       axios
//         .get(`${API_BASE_URL}/user/getalladspost/${categoryName}/${subCategoryName}`)
//         .then((res) => {
//           const adLocations = res?.data?.Posts.map(post => post.PostDetails?.Location);
//           console.log("Fetched ad locations:", adLocations);
//           setAds(adLocations);
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [currentSubCategory]);

//   // Normalize segments and ads for comparison
//   const normalizeString = (str) => str.trim().toLowerCase().replace(/-/g, " ").replace(/\s+$/, "");

//   // Log path segments and exclude segments
//   const pathSegments = location.pathname
//     .split("/")
//     .filter((path) => path !== "");
//   console.log("Path segments:", pathSegments);

//   const excludeSegments = ads.flat().map(normalizeString); // Normalize ad locations
//   console.log("Exclude segments:", excludeSegments);

//   const filteredSegments = pathSegments.filter(
//     (segment) => !excludeSegments.includes(normalizeString(segment))
//   );
//   console.log("Filtered segments:", filteredSegments);

//   // Generate breadcrumb links
//   let crumbLink = "";
//   const crumbs = filteredSegments.map((crumb, index, array) => {
//     crumbLink += `/${crumb}`;
//     return index === array.length - 1 ? (
//       <Typography key={crumb} color="textPrimary">
//         {crumb.replace(/-/g, " ")}
//       </Typography>
//     ) : (
//       <Link key={crumb} to={crumbLink} className="text-decoration-none ">
//         {crumb.replace(/-/g, " ")}
//       </Link>
//     );
//   });
// console.log(crumbs)
//   return (
//     <div className="m-2 ms-2">
//       <Breadcrumbs aria-label="breadcrumb" className="text-decoration-none ">
//       {/* <Link to={"/"}>Home */}
//       {/* </Link>  {crumbs} */}
//       {crumbs}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import userContext from "../../context";
import { API_BASE_URL } from "../../utils";
import  "../breadcrumb/Breadcrumbs.css"
const BreadCrumbs = () => {
  const location = useLocation();
  const { subcategory } = useParams();
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const [ads, setAds] = useState([]);
  const { setGetcategory, setGetSubCategory } = useContext(userContext);

  // Update category and subcategory in context
  useEffect(() => {
    if (currentSubCategory) {
      const categoryName = currentSubCategory.Main_Category?.Main_Category;
      const subCategoryName = currentSubCategory.SubCategory;
      setGetcategory(categoryName);
      setGetSubCategory(subCategoryName);
    }
  }, [currentSubCategory, setGetcategory, setGetSubCategory]);

  // Fetch all subcategories
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallSubcategory`)
      .then((res) => setAllSubCategory(res.data.SubCategory))
      .catch((error) => console.log("error", error));
  }, []);

  // Find and set the current subcategory based on the URL
  useEffect(() => {
    if (subcategory && allSubCategory.length > 0) {
      const SubategoryName = subcategory.replace(/-/g, " ");
      const findCategory = allSubCategory.find(
        (cat) => cat.SubCategory.toLowerCase() === SubategoryName.toLowerCase()
      );
      setCurrentSubCategory(findCategory);
    }
  }, [subcategory, allSubCategory]);

  // Fetch ads based on the current subcategory
  useEffect(() => {
    if (currentSubCategory) {
      const categoryName = currentSubCategory.Main_Category?.Main_Category;
      const subCategoryName = currentSubCategory.SubCategory;
      axios
        .get(`${API_BASE_URL}/user/getalladspost/${categoryName}/${subCategoryName}`)
        .then((res) => {
          const adLocations = res?.data?.Posts.map(post => post.PostDetails?.Location);
          console.log("Fetched ad locations:", adLocations);
          setAds(adLocations);
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentSubCategory]);

  // Normalize segments and ads for comparison
  const normalizeString = (str) => str.trim().toLowerCase().replace(/-/g, " ").replace(/\s+$/, "");

  // Log path segments and exclude segments
  const pathSegments = location.pathname
    .split("/")
    .filter((path) => path !== "");
  console.log("Path segments:", pathSegments);

  const excludeSegments = ads.flat().map(normalizeString); // Normalize ad locations
  console.log("Exclude segments:", excludeSegments);

  const filteredSegments = pathSegments.filter(
    (segment) => !excludeSegments.includes(normalizeString(segment))
  );
  console.log("Filtered segments:", filteredSegments);

  // Generate breadcrumb links
  let crumbLink = "";
  const crumbs = filteredSegments.map((crumb, index, array) => {
    crumbLink += `/${crumb}`;
    return index === array.length - 1 ? (
      <Typography key={crumb} color="text-black">
        {crumb.replace(/-/g, " ")}
      </Typography>
    ) : (
      <Link key={crumb} to={crumbLink} className="text-decoration-none text-black ">
        {crumb.replace(/-/g, " ")}
      </Link>
    );
  });

  // Add "Home" link at the beginning
  if (location.pathname !== "/"){

    crumbs.unshift(
      <Link key="home" to="/" className="text-decoration-none text-black">
        Home
      </Link>
    );
  }

  console.log(crumbs);
  return (
    <div className="m-2 ms-2 container">
      <Breadcrumbs aria-label="breadcrumb" className="text-decoration-none ms-5">
    
        {crumbs}
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbs;
