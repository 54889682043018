import React from "react";

function SubTypes({
  setSubRolesData,
  subRolesData,
  subRoles,
  subDesriptionName,
}) {

  const handleCheckboxChange = (index, itemName) => {
    const newSubRolesData = [...subRolesData];
    const newCheckedItems = [...newSubRolesData[0].value];

    newCheckedItems[index] = !newCheckedItems[index];

    if (newCheckedItems[index]) {
      newSubRolesData[0].value[index] = itemName;
    } else {
      newSubRolesData[0].value[index] = null;
    }

    setSubRolesData(newSubRolesData);
  };

  return (
    <>
      {subRoles.length !== 0 && (
        <div className="col-md-12">
          <label className="post-title">{subDesriptionName}</label>

          <div className="sub-roles-container">
            {subRoles.map((item, i) => (
              <div className="checkbox-wrapper-43 m-1" key={i}>
                <input
                  type="checkbox"
                  id={`cbx-${i}`}
                  onChange={() => handleCheckboxChange(i, item)}
                  checked={subRolesData[0].value[i]}
                />

                <label htmlFor={`cbx-${i}`} className="check">
                  <svg width="18px" height="18px" viewBox="0 0 18 18">
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                    <polyline points="1 9 7 14 15 4"></polyline>
                  </svg>
                </label>
                <label htmlFor={`cbx-${i}`} className="checkbox-name">
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default SubTypes;
