import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../../styles/adsCard.scss";
import CardAds from "../CardAds";
import PrimaryButton from "../../buttons/PrimaryButton";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";

function Index() {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallmaincategory `)
      .then((res) => {
        // console.log("res", res.data.mainCategories);
        setCategory(res.data.mainCategories);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="container featued-ads-container">
      {/* <center className="featued-ads-title my-2">Featued Ads</center> */}
      <Tabs
        defaultActiveKey={category[0]?.Main_Category}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {category.map((item, i) => (
          <Tab key={i} eventKey={item.Main_Category} title={item.Main_Category}>
            <CardAds categoryName={item?.Main_Category} />
          </Tab>
        ))}
      </Tabs>
      <center>{/* <PrimaryButton data="View More" /> */}</center>
    </div>
  );
}

export default Index;
