import React, { Fragment } from "react";
import "../../styles/footer.scss";
import { Link } from "react-router-dom";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import insta_icon from "../../assets/images/insta_icon.svg";
import linkedin_icon from "../../assets/images/linkedin_icon.svg";
import twitter_icon from "../../assets/images/twitter_icon.svg";
import PrimaryButton from "../buttons/PrimaryButton";
import Logo from "../../assets/images/logo192.jpg"


function index() {
  const links = [
    {
      title: "Quick Links",
      items: [
        {
          link: "About",
          path: "/",
        },
        {
          link: "Blog",
          path: "/",
        },
        {
          link: "Post a Ads",
          path: "/",
        },
        {
          link: "Contact",
          path: "/",
        },
        {
          link: "Get Membership",
          path: "/",
        },
      ],
    },
    {
      title: "Category",
      items: [
        {
          link: "Jobs",
          path: "/",
        },
        {
          link: "Education",
          path: "/",
        },
        {
          link: "Travel",
          path: "/",
        },
        {
          link: "Stores",
          path: "/",
        },
        {
          link: "Events",
          path: "/",
        },
        {
          link: "Services",
          path: "/",
        },
        {
          link: "Automobiles",
          path: "/",
        },
      ],
    },
  ];

  const socialmedialinks = [
    {
      icons: facebook_icon,
      path: "/",
    },
    {
      icons: insta_icon,
      path: "/",
    },
    {
      icons: linkedin_icon,
      path: "/",
    },
    {
      icons: twitter_icon,
      path: "/",
    },
  ];

  return (
    <Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              {/* <h3>Logo Here</h3> */}
              <img src={Logo}  width="100px" height="100px" style={{borderRadius:"10%",backdropFilter:"100"}}/>
              <p className="footer-items m-0">
                No:S19, Subham Complex 2nd Floor, North Park Street, Ambattur
                O.T, Chennai, Tamil Nadu – 600053
              </p>
              <div className="d-flex footer-contact">
                <label htmlFor="">Phone :</label>
                <span>+91 - 9150****00</span>
              </div>
              <div className="d-flex footer-contact">
                <label htmlFor="">Mail :</label>
                <span>info@pinandfind.com</span>
              </div>
            </div>
            {links.map((items, i) => (
              <div className="col-lg-2 col-sm-6" key={i}>
                <h5 className="links-title"> {items.title} </h5>
                {items?.items.map((links, index) => (
                  <div key={index}>
                    <Link className="footer-items">{links.link}</Link>
                  </div>
                ))}
              </div>
            ))}
            <div className="col-lg-5 d-flex justify-content-end">
              <div>
                <h5 className="links-title">Join a Newsletter</h5>
                <form className="footer-form">
                  <label htmlFor="">Your Email</label> <br />
                  <div className="inputs">
                    <input type="email" required />
                    <PrimaryButton data="Subscribe" />
                  </div>
                </form>
                <div className="flow-as">
                  <label htmlFor="">Follow Us</label>
                  <div className="icons">
                    {socialmedialinks.map((item, index) => (
                      <Link to={item.path} key={index}>
                        <img src={item.icons} alt="icon" />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy-rights">
        <div className="container">
          <div className="copy-rights-inner">
            <Link to="" className="m-0">
              © 2024 .All Right Reserved. Design and Developed by{" "}
              <span>WEBDADS2U</span> PVT LTD
            </Link>

            <div className="d-flex  align-items-center">
              <p className="m-0 privacy-policy">Privacy Policy </p>
              <p className="m-0 terms-condition">Terms & Condition</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default index;
