import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { API_BASE_URL } from "../../utils";
import PrimaryButton from "../buttons/PrimaryButton";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import toast, { Toaster } from "react-hot-toast";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

function ApplyModals({ show, setShow, item }) {
  const handleClose = () => setShow(false);
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({});
  const productId = item._id;

  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems.map((fileItem) => fileItem.file));
  };

  const handleProcessFile = (error, file) => {
    if (error) {
      console.error("Error processing file:", error);
      return;
    }
    console.log("Processed file:", file);
  };

  const handleFormData = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fields = [
    {
      label: "Email Address",
      type: "email",
      name: "email",
      placeholder: "enter your email",
    },
    {
      label: "Mobile Number",
      type: "number",
      name: "number",
      placeholder: "enter your mobile no",
    },
    {
      label: "Message",
      type: "textarea",
      name: "message",
      placeholder: "enter your message",
    },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const fetchData = () => {
      return axios
        .post(`${API_BASE_URL}/user/response`, {
          email: formData.email,
          message: formData.message,
          mobile: formData.number,
          AdposterId: productId,
          resumeUrl: files,
        })
        .then((res) => {
          console.log("res", res);
          setFiles([]);

          toast.success("Apply Successfully!");
          setShow(false);
          return res;
        })
        .catch((error) => {
          console.log("error", error);

          setShow(false);
          throw error;
        });
    };

    const myPromise = fetchData();

    toast.promise(myPromise, {
      loading: "Loading",
      success: "Apply Successfuly ",
      error: (error) => {
        // console.error("Error when fetching:", error.response?.data?.message);
        return error.response?.data?.message;
      },
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{item?.PostCategory}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            {item && item?.PostCategory?.toLowerCase() === "job" && (
              <FilePond
                files={files}
                allowMultiple={true}
                maxFiles={3}
                acceptedFileTypes={[
                  "application/pdf",
                  "application/msword",
                  "image/png",
                  "image/jpeg",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ]}
                maxFileSize={"5MB"}
                onupdatefiles={handleUpdateFiles}
                onprocessfile={handleProcessFile}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            )}

            {fields.map((item, i) => (
              <React.Fragment key={i}>
                {item.type === "textarea" ? (
                  <Form.Group className="mb-3" controlId={`formTextarea${i}`}>
                    <Form.Label>{item.label}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name={item.name}
                      onChange={handleFormData}
                      placeholder={item.placeholder}
                      required
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId={`formInput${i}`}>
                    <Form.Label>{item.label}</Form.Label>
                    <Form.Control
                      type={item.type}
                      placeholder={item.placeholder}
                      autoFocus
                      name={item.name}
                      onChange={handleFormData}
                      required
                    />
                  </Form.Group>
                )}
              </React.Fragment>
            ))}

            <PrimaryButton className="primary-btn" data="Submit" />
          </Form>
        </Modal.Body>
      </Modal>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
}

export default ApplyModals;
