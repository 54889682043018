import React from "react";

function CommonDesription({ descriptions, renderInput }) {
  return (
    <div className="row">
      {descriptions
        .filter((item) => item.type !== "Api Drop Down")
        .map((item, index) => (
          <div className="col-md-4" key={index}>
            <label className="post-title" htmlFor={item.Description}>
              {item.Description}
            </label>
            <br />
            {renderInput(item)}
          </div>
        ))}
    </div>
  );
}

export default CommonDesription;
