import React from "react";
import Lottie from "react-lottie";
import commin_soon from "../lottie/CommingSoon.json.json"

function CommingSoon() {
  return (
    <div className="animation">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: commin_soon,
          speed: 10,
        }}
        height={350}
        width={350}
      />
    </div>
  );
}

export default CommingSoon;
