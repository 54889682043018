import React from "react";
import { PremiumMembership, Banner, HeroAds, AdsCard, Category } from "../components";

function Home() {
  return ( 
    <> 
      <Banner />
      <Category />
      <HeroAds />
      <AdsCard />
      <PremiumMembership />
    </>
  );
}

export default Home;
