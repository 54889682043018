import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../Token";

function ResponseOtherAds() {
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/getresponse`, { headers })
      .then((res) => {
        setData(res.data.PostRespons);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="my-ads-container">
      <h5 className="profile-title">Response to others ads</h5>

      {data.map((item, i) => (
        // console.log("item", item)
        <>
          <div
            className="ads-details my-3 border-bottom d-flex  justify-content-between"
            key={i}
          >
            <div>
              <h6 className="ads-title m-0">
                {item?.AdPosterId?.postTittle}{" "}
                <span>( {item?.AdPosterId?.Response?.length} Reply)</span>
              </h6>
              <p className="ads-location">
                {item?.AdPosterId?.PostDetails?.Location} >{" "}
                {item?.AdPosterId?.PostSubCategory} >{" "}
                {item?.AdPosterId?.PostCategory}
              </p>
            </div>
            <div>
              {/* <button className="ads-details-btn mx-2">view more</button> */}
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default ResponseOtherAds;
