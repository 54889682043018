import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userContext from "../../context";
import ServiceReport  from './ServiceReportForm'
import { Link } from "react-router-dom";
import API_BASE_URL from '../../utils/index'
function PostButton({ setLgShow }) {
  const location = useLocation();
  const pathName = location.pathname;
  const { getCategory, getSubCategory } = useContext(userContext);
  const navigate = useNavigate();

  const handlePost = () => {
    const category = getCategory.trim().replace(/\s+/g, "-").toLowerCase();
    const subCategorySlug = getSubCategory.trim().replace(/\s+/g, "-").toLowerCase();

    console.log("category checking",category?category:"undefiend")
    console.log("subcategory checking",subCategorySlug?subCategorySlug:"undefiend")

    // navigate(`/form/${subCategorySlug}`);
    navigate(`/${category}/${subCategorySlug}/form`);
  };


  return (
    <div>
      <>
        {pathName === "/" || pathName === "/profile" ? (
          <>
          <button onClick={() => setLgShow(true)} className="post-add-btn">
            POST FREE AD
          </button>
          {/* <button  className="post-add-btn" style={{marginLeft:"5px"}}>
          
              <a href="https://pinandfind.com/asian-form" style={{textDecoration:"none"}} target="_blank" rel="noopener noreferrer">click</a>
          </button> */}
          </>
        ) : (
          <button className="post-add-btn" onClick={handlePost}>
            POST FREE {getCategory}
          </button>
        )}
      </>
    </div>
  );
}

export default PostButton;
