// import React, { createContext, useContext } from "react";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const tokenId = localStorage.getItem("token");

//   console.log("tokenId", tokenId);

//   const headers = {
//     Authorization: `Bearer ${tokenId}`,
//   };

//   return (
//     <AuthContext.Provider value={headers}>{children}</AuthContext.Provider>
//   );
// };

// export const useAuthHeaders = () => {
//   return useContext(AuthContext);
// };

// import React, { createContext, useContext, useState, useEffect } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../utils";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {

//   const [authHeaders, setAuthHeaders] = useState(() => {
//     const userId = localStorage.getItem("token");
// console.log(userId,"sssssssssssssssssssssssssssss")
//     return {
//       Authorization: `Bearer ${userId}`,
//     };
//   });

//   // api
//   const refreshToken = async () => {
//     const storedRefreshToken = localStorage.getItem("refreshToken");

//     try {
//       const response = await axios.post(
//         `${API_BASE_URL}/user/refreshaccesstoken`,

//         {
//           refreshToken: storedRefreshToken,
//         }
//       )

//       const newAccessToken = response.data.token;

//       console.log("response in refreshToken api", response.data.refreshToken);

//       return newAccessToken;
//     } catch (error) {
//       console.error("Error refreshing access token:", error);
//       throw error;
//     }
//   };
//   // api

//   useEffect(() => {
//     const refreshAccessToken = async () => {
//       try {
//         const newAccessToken = await refreshToken();

//         const userId = newAccessToken.token;
//         const newRefreshToken = newAccessToken.refreshToken;

//         localStorage.setItem("token", userId);
//         localStorage.setItem("refreshToken", newRefreshToken);

//         console.log("newAccessToken", newAccessToken);

//         setAuthHeaders({
//           Authorization: `Bearer ${newAccessToken.token}`,
//         });
//       } catch (error) {
//         console.error("Error refreshing access token:", error);
//       }
//     };

//     // const checkTokenInterval = setInterval(refreshAccessToken, 5000);

//     const checkTokenInterval = setInterval(refreshAccessToken, 3600000); // 1 hour interval

//     return () => clearInterval(checkTokenInterval);
//   }, []);

//   return (
//     <AuthContext.Provider value={authHeaders}>{children}</AuthContext.Provider>
//   );
// };

// export const useAuthHeaders = () => {
//   return useContext(AuthContext);
// };
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../utils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authHeaders, setAuthHeaders] = useState(() => {
    const userId = localStorage.getItem("token");

    return {
      Authorization: `Bearer ${userId}`,
    };
  });

  // api
  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem("refreshToken");
    console.log(storedRefreshToken, "Ssssssssssss storage  s");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/refreshaccesstoken`,
        {
          refreshToken: storedRefreshToken,
        }
      );
      console.log(response.data, "from Token");
      const newAccessToken = response?.data;

      console.log("response in refreshToken api", response.data);

      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  };
  // api

  useEffect(() => {
    if (localStorage.getItem("token")) {
    const refreshAccessToken = async () => {
      try {
        const newAccessToken = await refreshToken();

        const userId = newAccessToken?.token;
        const newRefreshToken = newAccessToken?.refreshToken;

        localStorage.setItem("token", userId);
        localStorage.setItem("refreshToken", newRefreshToken);

        setAuthHeaders({
          Authorization: `Bearer ${newAccessToken?.token}`,
        });
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    // const checkTokenInterval = setInterval(refreshAccessToken, 12000);

    // const checkTokenInterval = setInterval(refreshAccessToken, 3600000);
    const checkTokenInterval = setInterval(refreshAccessToken, 15000);

    return () => clearInterval(checkTokenInterval);

    }
  }, []);

  return (
    <AuthContext.Provider value={authHeaders}>{children}</AuthContext.Provider>
  );
};

export const useAuthHeaders = () => {
  return useContext(AuthContext);
};
