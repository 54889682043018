


// import axios from "axios";
// import React, { useEffect, useState, useRef } from "react";
// import Modal from "react-bootstrap/Modal";
// import Slider from "react-slick";
// import { API_BASE_URL } from "../../utils";
// import { useNavigate } from "react-router-dom";
// import "./CatAnSubCat.scss"; // Add the appropriate styling file

// const CatAnSubCat = ({ lgShow, setLgShow }) => {
//   const navigate = useNavigate();

//   const settings = {
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "60px",
//     slidesToShow: 5,
//     speed: 500,
//     draggable: true,
//     swipeToSlide: true,
//   };

//   const [data, setData] = useState([]);
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [isDragging, setIsDragging] = useState(false);

//   const startX = useRef(0);
//   const startY = useRef(0);

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/getallmaincategory`)
//       .then((res) => setData(res.data.mainCategories))
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   const handleMouseDown = (e) => {
//     setIsDragging(false);
//     startX.current = e.clientX;
//     startY.current = e.clientY;
//   };

//   const handleMouseMove = (e) => {
//     const dx = e.clientX - startX.current;
//     const dy = e.clientY - startY.current;
//     if (Math.sqrt(dx * dx + dy * dy) > 10) {
//       setIsDragging(true);
//     }
//   };

//   const handleMouseUp = (category, i) => {
//     if (!isDragging) {
//       setActiveIndex(i);
//       setLgShow(false); // Close the modal
//       navigate(`/${category}`);
//     }
//   };

//   return (
//     <Modal
//       size="lg"
//       show={lgShow}
//       onHide={() => setLgShow(false)}
//       aria-labelledby="example-modal-sizes-title-lg"
//       id="catansubcat"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="example-modal-sizes-title-lg" className="model-title">
//           Choose Ad
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="slider-container">
//           <Slider {...settings}>
//             {data.map((category, i) => (
//               <div
//                 key={i}
//                 className={`d-flex flex-column text-center slider-item ${
//                   activeIndex === i ? "slick-current" : ""
//                 }`}
//                 onMouseDown={handleMouseDown}
//                 onMouseMove={handleMouseMove}
//                 onMouseUp={() => handleMouseUp(category?.Main_Category, i)}
//               >
//                 <img
//                   src={category?.Main_Category_icon}
//                   height={50}
//                   alt="category-icon"
//                   style={{ objectFit: "contain" }}
//                 />
//                 <p className="m-0">{category?.Main_Category}</p>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default CatAnSubCat;














// import axios from "axios";
// import React, { useEffect, useState, useRef } from "react";
// import Modal from "react-bootstrap/Modal";
// import Slider from "react-slick";
// import { API_BASE_URL } from "../../utils";
// import { useNavigate } from "react-router-dom";
// import "./CatAnSubCat.scss"; // Add the appropriate styling file

// const CatAnSubCat = ({ lgShow, setLgShow }) => {
//   const navigate = useNavigate();

//   const settings = {
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "60px",
//     slidesToShow: 5,
//     speed: 500,
//     draggable: true,
//     swipeToSlide: true,
//     responsive: [
//       {
//         breakpoint: 1200, // screens smaller than 1200px
//         settings: {
//           slidesToShow: 5,
//         },
//       },
//       {
//         breakpoint: 992, // screens smaller than 992px
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 768, // screens smaller than 768px
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 576, // screens smaller than 576px
//         settings: {
//           slidesToShow: 3,
//         },
//       }, {
//         breakpoint: 320, // screens smaller than 576px
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//     ],
//   };

//   const [data, setData] = useState([]);
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [isDragging, setIsDragging] = useState(false);

//   const startX = useRef(0);
//   const startY = useRef(0);

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/getallmaincategory`)
//       .then((res) => setData(res.data.mainCategories))
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   const handleMouseDown = (e) => {
//     setIsDragging(false);
//     startX.current = e.clientX;
//     startY.current = e.clientY;
//   };

//   const handleMouseMove = (e) => {
//     const dx = e.clientX - startX.current;
//     const dy = e.clientY - startY.current;
//     if (Math.sqrt(dx * dx + dy * dy) > 10) {
//       setIsDragging(true);
//     }
//   };

//   const handleMouseUp = (category, i) => {
//     if (!isDragging) {
//       setActiveIndex(i);
//       setLgShow(false); // Close the modal
//       navigate(`/${category}`);
//     }
//   };

//   return (
//     <Modal
//       size="lg"
//       show={lgShow}
//       onHide={() => setLgShow(false)}
//       aria-labelledby="example-modal-sizes-title-lg"
//       id="catansubcat"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="example-modal-sizes-title-lg" className="model-title">
//           Choose Ad
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="slider-container">
//           <Slider {...settings}>
//             {data.map((category, i) => (
//               <div
//                 key={i}
//                 className={`d-flex flex-column text-center slider-item ${
//                   activeIndex === i ? "slick-current" : ""
//                 }`}
//                 onMouseDown={handleMouseDown}
//                 onMouseMove={handleMouseMove}
//                 onMouseUp={() => handleMouseUp(category?.Main_Category, i)}
//               >
//                 <img
//                   src={category?.Main_Category_icon}
//                   height={50}
//                   alt="category-icon"
//                   style={{ objectFit: "contain" }}
//                 />
//                 <p className="m-0">{category?.Main_Category}</p>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default CatAnSubCat;



import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import { API_BASE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";
import "./CatAnSubCat.scss"; // Add the appropriate styling file

const CatAnSubCat = ({ lgShow, setLgShow }) => {
  const navigate = useNavigate();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    speed: 500,
    draggable: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200, // screens smaller than 1200px
        settings: {
          slidesToShow: 4,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 992, // screens smaller than 992px
        settings: {
          slidesToShow: 4,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768, // screens smaller than 768px
        settings: {
          slidesToShow: 3,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 576, // screens smaller than 576px
        settings: {
          slidesToShow: 2,
          centerPadding: "10px", // Reduce padding for smaller screens
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const startX = useRef(0);
  const startY = useRef(0);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallmaincategory`)
      .then((res) => setData(res.data.mainCategories))
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(false);
    startX.current = e.clientX;
    startY.current = e.clientY;
  };

  const handleMouseMove = (e) => {
    const dx = e.clientX - startX.current;
    const dy = e.clientY - startY.current;
    if (Math.sqrt(dx * dx + dy * dy) > 10) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = (category, i) => {
    if (!isDragging) {
      setActiveIndex(i);
      setLgShow(false); // Close the modal
      navigate(`/${category}`);
    }
  };

  return (
    <Modal
      size="lg"
      show={lgShow}
      onHide={() => setLgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      id="catansubcat"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg" className="model-title">
          Choose Ad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="slider-container">
          <Slider {...settings}>
            {data.map((category, i) => (
              <div
                key={i}
                className={`d-flex flex-column text-center slider-item ${
                  activeIndex === i ? "slick-current" : ""
                }`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={() => handleMouseUp(category?.Main_Category, i)}
              >
                <img
                  src={category?.Main_Category_icon}
                  height={50}
                  alt="category-icon"
                  style={{ objectFit: "contain" }}
                />
                <p className="m-0">{category?.Main_Category}</p>
              </div>
            ))}
          </Slider>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CatAnSubCat;
