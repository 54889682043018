import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";

function AdsDetailTable({ data }) {
  console.log("data", data);
  return (
    <div className="ads-tabs-wrapper">
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title="Job Details">
          <div className="row">
            <div className="col-md-6">
              {data?.Descriptions?.map((item, i) => (
                <div className="ads-description" key={i}>
                  <p className="m-0">{item.name} :</p>
                  <p className="m-0 px-2">{item.value}</p>
                </div>
              ))}
            </div>

            {data?.DescriptionType && (
              <div className="col-md-6 ads-description align-items-start my-4">
                <p className="m-0">{data?.DescriptionType?.name} :</p>
                <div>
                  {data?.DescriptionType?.value?.map((item, i) => (
                    <p className="m-0 mb-2 px-1" key={i}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Tab>
        <Tab eventKey="profile" title="Description">
          <>
            <Link
              className="text-decoration-none"
              to={`mailto:${data?.PostDetails?.Email}`}
            >
              <div className="ads-description">
                <p className="m-0">Email :</p>
                <p className="m-0"> {data?.PostDetails?.Email} </p>
              </div>
            </Link>

            <Link
              className="text-decoration-none"
              to={`tel:${data?.PostDetails?.PhoneNumber}`}
            >
              <div className="ads-description">
                <p className="m-0">Contact :</p>
                <p className="m-0"> {data?.PostDetails?.PhoneNumber} </p>
              </div>
            </Link>
            <div className="ads-description">
              <p className="m-0">Location :</p>
              <p className="m-0"> {data?.PostDetails?.Location} </p>
            </div>
            <div className="ads-description">
              <p className="m-0">City :</p>
              <p className="m-0"> {data?.PostDetails?.City} </p>
            </div>
          </>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AdsDetailTable;
