import React, { useEffect, useState } from "react";
import "../../styles/banner.scss";
import search_icon from "../../assets/images/search_icon.svg";
import location_icon from "../../assets/images/location_icon.svg";
import search_icon_white from "../../assets/images/search_icon_white.svg";
import category_icon from "../../assets/images/category_icon.svg";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";

function Index() {
  const [searchTitle, setSearchTitle] = useState("");
  const [location, setLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();

  const searchInputs = [
    {
      icon: search_icon,
      placeholder: "Search by ads title, keyword",
      value: searchTitle,
      onChange: (e) => setSearchTitle(e.target.value),
    },
    {
      icon: location_icon,
      placeholder: "Locations",
      value: location,
      onChange: (e) => setLocation(e.target.value),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const keyWord = searchTitle.replace(/\s+/g, "-").toLowerCase();
    const locations = location.replace(/\s+/g, "-").toLowerCase();
    const categoryName = selectedCategory.replace(/\s+/g, "-").toLowerCase();

    navigate(`/${categoryName}/${locations}/${keyWord}`);
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallmaincategory `)
      .then((res) => {
        // console.log("res", res.data.mainCategories);
        setCategory(res.data.mainCategories);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="banner">
      <div className="container">
        <div className="banner-inner">
          <h1 className="banner-heading">Pin & Find just about anything</h1>
          <p className="m-0 banner-content">
            Explore our platform to find just about anything you need, from
            unique treasures to everyday essentials. Start your search and
            discover endless possibilities!
          </p>
          <form className="banner-input-container" onSubmit={handleSubmit}>
            {searchInputs.map((item, i) => (
              <div className="input-section br-input-section" key={i}>
                <img src={item.icon} alt="icon" width={22} height={22} />
                <input
                  type="text"
                  // required
                  placeholder={item.placeholder}
                  onChange={item.onChange}
                />
              </div>
            ))}

            <div className="input-section">
              <img src={category_icon} alt="icon" width={22} height={22} />
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
                style={{paddingLeft:"5%"}}
              >
                <option>Select Category</option>
                {category.map((item, index) => (
                  <option value={item.Main_Category} key={index}>
                    {item.Main_Category}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div
              className="input-section btn-input-section"
              style={{ paddingLeft: "0" }}
            >
              <button className="search-btn">
                <img
                  src={search_icon_white}
                  alt="icon"
                  width={22}
                  height={22}
                />
                <span>Search</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Index;
