import React from "react";

function AuthContent() {
  return (
    <div className="auth-content">
      <div className="box">
        <h1>Welcome to Pin&Find</h1>
        <p className="m-0">
          Let’s get you all set up so you can verify your personal account and
          begin setting up your profile
        </p>
      </div>
    </div>
  );
}

export default AuthContent;
