import React, { useEffect } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose the theme you want
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';    
function SearchInput({
  ads,
  setAds,
  setSearchQuery,
  searchQuery,
  setDataLoading,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (ads) {
      const filteredResults = ads.filter((item) => {
        const postTitle = item.postTittle
          ? item.postTittle.toLowerCase().trim()
          : "";

        const location = item.PostDetails.Location
          ? item.PostDetails.Location.toLowerCase().trim()
          : "";

        const city = item.PostDetails.City
          ? item.PostDetails.City.toLowerCase().trim()
          : "";

        return (
          postTitle.includes(searchQuery.toLowerCase().trim()) ||
          location.includes(searchQuery.toLowerCase().trim()) ||
          city.includes(searchQuery.toLowerCase().trim())
        );
      });

      setAds(filteredResults);
    }
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(() => query);

    if (query == "") {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  };

  const handleResultPage = (item) => {
    const category = item?.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = item?.PostSubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const location = item?.PostDetails?.Location?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const slug = item?.slug?.replace(/\s+/g, "-").toLowerCase();

    navigate(`/${category}/${subCategory}/${location}/${slug}`);
  };

  return (
    <center>
      <div className="search-input-container">
    
        <input
          type="search"
          className="search-input"
          placeholder="Search by ads title, location, city"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        
        {searchQuery && (
          <div className="search-results">
            <ul>
              {ads.map((item, index) => (
                <li key={index} onClick={() => handleResultPage(item)}>
                  <span>
                    {item.postTittle}, {item?.PostDetails?.Location},{" "}
                    {item?.PostDetails?.City}
                  </span>
                  <span>
                    <BsBoxArrowUpRight className="result-icon" />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </center>
  );
}

export default SearchInput;
