import React, { useState } from "react";
import { AuthContent, PagePurpose, SuggesLink } from "../../components";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AuthSocialMediaLinks from "../../components/authComponents/AuthSocialMediaLinks";
import "../../styles/authPages.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import toast, { Toaster } from "react-hot-toast";

function Register() {
  const [hidePassword, setHidePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputFields = [
    { type: "text", placeholder: "Full Name", name: "name" },
    { type: "email", placeholder: "Email ID", name: "email" },
    { type: "number", placeholder: "Phone Number", name: "mobile" },
    {
      type: hidePassword ? "text" : "password",
      placeholder: "Password",
      toggleVisibility: togglePasswordVisibility,
      hide: hidePassword,
      name: "password",
    },
    {
      type: hideConfirmPassword ? "text" : "password",
      placeholder: "Confirm Password",
      toggleVisibility: toggleConfirmPasswordVisibility,
      hide: hideConfirmPassword,
      name: "confirmpassword",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/user/register`, {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
        confirmpassword: formData.confirmpassword,
      })
      .then((res) => {
        toast.success("Register Successfully!");
        navigate("/login");
      })
      .catch((error) => toast.error(error.response.data.message));
  };

  return (
    // <div className="auth-container">
    //   <AuthContent />
    //   {/* <div className="auth-input-container container">
    //     <div className="box">
    //       <h4 className="auth-title">Register</h4>
    //       <p className="m-0">Create your account</p>

    //       <form onSubmit={handleSubmit}>
    //         {inputFields.map((field, index) => (
    //           <div
    //             key={index}
    //             className={field.toggleVisibility ? "password-input" : ""}
    //           >
    //             <input
    //               type={field.type}
    //               required
    //               placeholder={field.placeholder}
    //               name={field.name}
    //               value={formData[field.name]}
    //               onChange={handleInputChange}
    //             />

    //             {field.toggleVisibility && (
    //               <button type="button" onClick={field.toggleVisibility}>
    //                 {field.hide ? (
    //                   <FaEyeSlash className="eye-icon" />
    //                 ) : (
    //                   <FaEye className="eye-icon" />
    //                 )}
    //               </button>
    //             )}
    //           </div>
    //         ))}

    //         <PrimaryButton className="submit-btn" data="Register" />
    //       </form>

    //       <PagePurpose data="Or register with" />

    //       <AuthSocialMediaLinks />

    //       <SuggesLink
    //         data="Already registered?"
    //         path="/login"
    //         pageName="Login"
    //       />
    //     </div>
    //   </div> */}

    //     <div className="boxs">
    //       <h4 className="auth-title ">Register</h4>
    //       <p className="m-0">Create your account</p>

    //       <form onSubmit={handleSubmit}>
    //         <div className="row g-3">
    //           {inputFields.map((field, index) => (
    //             <div
    //               key={index}
    //               className={`col-12 ${
    //                 field.toggleVisibility ? "position-relative" : ""
    //               }`}
    //             >
    //               <input
    //                 type={field.type}
    //                 required
    //                 // className="form-control"
    //                 placeholder={field.placeholder}
    //                 name={field.name}
    //                 value={formData[field.name]}
    //                 onChange={handleInputChange}
    //               />
    //               {field.toggleVisibility && (
    //                 <button
    //                   type="button"
    //                   onClick={field.toggleVisibility}
    //                   className="btn position-absolute end-0 top-0"
    //                 >
    //                   {field.hide ? (
    //                     <FaEyeSlash className="eye-icon" />
    //                   ) : (
    //                     <FaEye className="eye-icon" />
    //                   )}
    //                 </button>
    //               )}
    //             </div>
    //           ))}
    //         </div>
    //         <div className="mt-4">
    //           <PrimaryButton className="submit-btn w-100" data="Register" />
    //         </div>
    //       </form>

    //       <PagePurpose data="Or register with" className="my-4" />

    //       <AuthSocialMediaLinks />

    //       <SuggesLink
    //         data="Already registered?"
    //         path="/login"
    //         pageName="Login"
    //         className="mt-3 d-block"
    //       />
    //     </div>

    //   <Toaster position="bottom-center" reverseOrder={false} />
    // </div>
    <>
      <div className="auth-container">
        <AuthContent />
        <div className="auth-input-container">
          <div className="boxs">
            <h4 className="auth-title">Register</h4>
            <p className="m-0">Create your account</p>

            <form onSubmit={handleSubmit}>
              {/* <div className="otp-inputs"> */}
                {inputFields.map((field, index) => (
                  <div
                    key={index}
                    className={field.toggleVisibility ? "password-input" : ""}
                  >
                    <input
                      key={index}
                      type={field.type}
                      required
                      placeholder={field.placeholder}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                     
                    />
                    {field.toggleVisibility && (
                      <button type="button" onClick={field.toggleVisibility}>
                        {field.hide ? (
                          
                          <FaEye className="eye-icon" />
                        ) : (
                          <FaEyeSlash className="eye-icon" />
                        )}
                      </button>
                    )}
                  </div>
                ))}
          

              <PrimaryButton className="submit-btn w-100" data="Register" />
            </form>

            <PagePurpose data="Or register with" />
            <AuthSocialMediaLinks />

            <SuggesLink
              data="Already registered?"
              path="/login"
              pageName="Login"
            />
          </div>
        </div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
    </>
  );
}

export default Register;
