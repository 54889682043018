import React, { useContext, useState } from "react";
import { AuthContent, PagePurpose, SuggesLink } from "../../components";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AuthSocialMediaLinks from "../../components/authComponents/AuthSocialMediaLinks";
import "../../styles/authPages.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import toast, { Toaster } from "react-hot-toast";
import userContext from "../../context";
// import { useAuthHeaders } from "../../components/Token";

function Login() {
  // const headers =useAuthHeaders()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const profileContent = useContext(userContext);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [hidePassword, setHidePassword] = useState(false);

  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
  };

  const inputFields = [
    { type: "text", placeholder: "Email address", name: "email" },
    {
      type: hidePassword ? "text" : "password",
      placeholder: "8+ strong password",
      name: "password",
      toggleVisibility: togglePasswordVisibility,
      hide: hidePassword,
    },
  ];

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   sessionStorage.setItem("userEmail",formData.email)

  //   axios
  //     .post(`${API_BASE_URL}/user/login`, {
  //       email: formData.email,
  //       password: formData.password,
  //     })
  //     .then((res) => {
  //       // console.log("res", res.data);
  //       localStorage.setItem("refreshToken", res.data.refreshToken);
  //       // console.log(res.data.refreshToken)
  //       localStorage.setItem("token", res.data.token);
  //       profileContent.setProfileActive(true);
  //       toast.success("Login Successfully!");
  //       navigate("/Otp");
  //     })
  //     .catch((error) => toast.error(error.response.data.message));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      sessionStorage.setItem("userEmail", formData.email);
  
      const response = await axios.post(`${API_BASE_URL}/user/login`, {
        email: formData.email,
        password: formData.password,
      });
  
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("token", response.data.token);
  
      profileContent.setProfileActive(true);
      toast.success("Login Successfully!");
      navigate("/Otp");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred during login. Please try again.");
      }
    }
  };
  
  return (
    <div className="auth-container">
      <AuthContent />
      <div className="auth-input-container">
        <div className="boxs">
          <h4 className="auth-title">Log in</h4>
          <p className="m-0">
            Enter your email and password to login to your account.
          </p>

          <form onSubmit={handleSubmit}>
            {inputFields.map((field, index) => (
              <div
                key={index}
                className={field.toggleVisibility ? "password-input" : ""}
              >
                <input
                  type={field.type}
                  required
                  placeholder={field.placeholder}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
                {field.toggleVisibility && (
                  <button type="button" onClick={field.toggleVisibility}>
                    {field.hide ? (
                             <FaEye className="eye-icon" />
                    ) : (
                      <FaEyeSlash className="eye-icon" />
          
                    )}
                  </button>
                )}
              </div>
            ))}

            <div className="options">
              <div className="d-flex my-2">
                <input type="checkbox" name="" id="Remember" />
                <label htmlFor="Remember">Remember me</label>
              </div>
              <Link to="/forget-password">Forget password?</Link>
            </div>

            <PrimaryButton className="submit-btn" data="Log in"  />

          </form>

          <PagePurpose data="Or sign in with" />
          <AuthSocialMediaLinks />
          <SuggesLink
            data="Don’t have an account?"
            path="/register"
            pageName="Create now"
          />
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default Login;
