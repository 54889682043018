// import React from "react";
// import { Link } from "react-router-dom";
// import facebook from "../../assets/images/facebook_logo.svg";
// import google from "../../assets/images/google_logo.svg";
// import twitter from "../../assets/images/twitter_logo.svg";

// function AuthSocialMediaLinks() {
//   const links = [
//     {
//       icon: facebook,
//       path: "",
//     },
//     {
//       icon: google,
//       path: "",
//     },
//     {
//       icon: twitter,
//       path: "",
//     },
//   ];

//   return (
//     <div className="auth-social-media-links">
//       {links.map((item, i) => (
//         <Link path={item.path} className="link-box" key={i}>
//           <img src={item.icon} alt="facebook" />
//         </Link>
//       ))}
//     </div>
//   );
// }

// export default AuthSocialMediaLinks;



import React from "react";
import { Link } from "react-router-dom";
import facebook from "../../assets/images/facebook_logo.svg";
import google from "../../assets/images/google_logo.svg";
import twitter from "../../assets/images/twitter_logo.svg";

function AuthSocialMediaLinks() {
  const links = [
    {
      icon: facebook,
      path: "",
    },
    {
      icon: google,
      path: "",
    },
    {
      icon: twitter,
      path: "",
    },
  ];

  return (
    <div className="container">
      <div className="row justify-content-center">
        {links.map((item, i) => (
          <div className="col-4 col-sm-3 col-md-2 d-flex justify-content-center mb-3" key={i}>
            <Link to={item.path} className="d-flex align-items-center justify-content-center">
              <img src={item.icon} alt="social media icon" className="img-fluid" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuthSocialMediaLinks;
