import React from "react";
import AdsSubmitButton from "../buttons/AdsSubmitButton";
import { useNavigate } from "react-router-dom";

function Index({ data, simlarData }) {
  const navigate = useNavigate();

  const handleSingleProduct = (data) => {
    console.log("coming to simler");
    const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const location = data.PostDetails?.Location?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
  };

  return (
    <div className="simpler-products my-4">
      <h4>Simlar {data.PostCategory}</h4>
 
      <div className="row">
        {simlarData && (
          <>
            {simlarData?.map((item, index) => (
              <div className="col-sm-6" key={index}>
                <div className="card-wrapper">
                  <div className="card mb-3 h-100">
                    <div className="row g-0">
                      <div className="col-lg-4">
                        <img
                          src={item.PosterImage}
                          className="img-fluid rounded-start card-image"
                          alt="ads-image"
                          onClick={() => handleSingleProduct(item)}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            onClick={() => handleSingleProduct(item)}
                          >
                            {item.postTittle}
                          </h5>
                          <div className="card-spaks">
                            {item.Descriptions?.slice(0, 3).map((item, i) => (
                              <div key={i}>
                                <label htmlFor="">{item.name}</label>
                                <p className="m-0">{item.value}</p>
                              </div>
                            ))}
                          </div>

                          <p className="card-text m-0 py-2">
                          <span style={{display:"block",fontWeight:"2px"}} className="mx-1">Description</span>
                            <small className="text-body-secondary">
                              {item.postDescription}
                            </small>
                          </p>
                        </div>
                        <div className="card-footer">
                          {/* <p className="m-0 ">
                            <span className="mx-1">Posted:</span>
                            {formatDistanceToNow(new Date(item.createdAt), {
                              addSuffix: true,
                            })}
                            <span className="mr-1">by a company</span>
                          </p> */}

                          <div>
                            {/* <IoIosHeartEmpty className="whislist" /> */}
                            <AdsSubmitButton item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Index;
