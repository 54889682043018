import React from "react";
import { FileUploader } from "react-drag-drop-files";

function PostStaticInputs({
  setPostTitle,
  setFile,
  setPostDesription,
  categoryName,
}) {
  const fileTypes = ["JPG", "PNG", "GIF"];

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label className="post-title" htmlFor="job-title">
            {categoryName} Title
          </label>
          <br />
          <input
            className="post-input"
            type="text"
            id="job-title"
            placeholder="Job Title"
            required
            onChange={(e) => setPostTitle(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label className="post-title" htmlFor="job-title">
            {categoryName} Image
          </label>
          <br />
          <div className="my-2">
            <FileUploader
              handleChange={handleChange}
              name="file"
              required
              types={fileTypes}
            />
          </div>
        </div>
      </div>

      <div>
        <label className="post-title" htmlFor="job-Desription">
          {categoryName} Desription
        </label>
        <br />
        <textarea
          className="post-input"
          type="text"
          id="job-Desription"
          required
          placeholder="Job Desription"
          onChange={(e) => setPostDesription(e.target.value)}
        />
      </div>
    </>
  );
}

export default PostStaticInputs;
