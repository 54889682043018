import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { Placeholder } from "react-bootstrap";

function PostSubCategory({
  selectedSubCategory,
  setSelectedSubCategory,
  categoryName,
}) {
  
  const categoryId = selectedSubCategory?.Main_Category?._id || "";
  const subCategoryId = selectedSubCategory?._id || "";

  const [subCategory, setSubCategory] = useState([]);
  const [activeCheckBox, setActiveCheckBox] = useState(subCategoryId);

  useEffect(() => {
    if (categoryId) {
      axios
        .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
        .then((response) => {
          setSubCategory(response.data.Subcategories);
        })
        .catch((error) => {
          console.error("Error fetching subcategory data:", error);
        });
    }
  }, [categoryId]);

  useEffect(() => {
    setActiveCheckBox(subCategoryId);
  }, [subCategoryId]);

  const handleSubCatChange = (item) => {
    setSelectedSubCategory(item);
    setActiveCheckBox(item._id);
  };

  return (
    <>
      <h6 className="m-0">{categoryName} Type</h6>

      <div className="post-sub-category">
        {subCategory.length === 0 ? (
          <>
            <div> 
              <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </div>
          </>
        ) : (
          <>
            {subCategory.map((item, i) => (
              <>
                <div className="checkbox-wrapper-46" key={i} >
                  <input
                    className="inp-cbx"
                    id={`cbx-46-${i}`}
                    type="checkbox"
                    checked={activeCheckBox === item._id}
                    onChange={() => handleSubCatChange(item)}
                  />
                  <label className="cbx" htmlFor={`cbx-46-${i}`}>
                    <span>
                      <svg width="12px" height="10px" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1" />
                      </svg>
                    </span>
                    <span className="subCategory-name">{item.SubCategory}</span>
                  </label>
                </div>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default PostSubCategory;
