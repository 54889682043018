import React, { useState } from "react";
import { AuthContent, PagePurpose, SuggesLink } from "../../components";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AuthSocialMediaLinks from "../../components/authComponents/AuthSocialMediaLinks";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/user/forgotpassword`, {
        email: email,
      })
      .then((res) => {
        toast.success("Forget Password Successfully!");
        navigate("/success-page");
      })
      .catch((error) => (
        // console.log("error forgot", error)
        toast.error(error.response.data.message)
      ));
  };

  return (
    <div className="auth-container">
      <AuthContent />
      <div className="auth-input-container">
        <div className="box">
          <h4 className="auth-title">Forget Password</h4>
          <p className="m-0">
            Enter your email on the Reset Password page. Link to your email.
          </p>

          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />

            <PrimaryButton className="submit-btn" data="Sent Mail" />
          </form>

          <PagePurpose data="Or Forget password in with" />
          <AuthSocialMediaLinks />
          <SuggesLink data="Back to Login" path="/login" pageName="Login" />
        </div>
      <Toaster position="bottom-center" reverseOrder={false} />

      </div>
    </div>
  );
}

export default ForgetPassword;
