import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import profile_icon from "../../assets/images/profile_icon.svg";

function AuthStatus() {
  const token = localStorage.getItem("token");
       
  return (
    <Fragment>
      {token ? (
        <Link to="/profile">
          <img
            src={profile_icon}
            width={33}
            height={33}
            className="profile-icon"
            alt="profile_icon"
          />
        </Link>
      ) : (
       <span style={{marginRight:"10px"}}>
        <Link to="/login" className="text-decoration-none">
          <p className="m-0 auth-status-link " style={{display:"inline-block"}}>Login /</p>
        </Link>
        <Link to="/register" className="text-decoration-none">
          <p className="m-0 auth-status-link " style={{display:"inline-block"}}>Register</p>
        </Link>
       </span>
      )}
    </Fragment>
  );
}

export default AuthStatus;
