import React from "react";
import "../../styles/home.scss";
import ads_image from "../../assets/images/main-ads-banner.jpg";

function HeroAds() {
  const ads = [
    // {
    //   image: ads_image,
    // },
  ];

  return (
    <div className="container my-4">
      {ads.length === 0 ? (
        <div className="ads-banner">
          <h6 className="m-0 title">Advertisement</h6>
          <div className="main-non-ads">
            <h3 className="heading">Ads</h3>
          </div>
        </div> 
      ) : (
        <div className="ads-banner-image">
          <img src={ads[0]?.image} alt="ads" />
        </div>
      )}
    </div>
  );
}

export default HeroAds;
