  // import axios from "axios";
  // import React, { useEffect, useState } from "react";
  // import { API_BASE_URL } from "../../utils";

// function Filters({ currentSubCategory, setAds }) {
//   const categoryId = currentSubCategory?.Main_Category?._id;
//   const subCategoryId = currentSubCategory?._id;
//   const [subCategory, setSubCategory] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [subCatFilterActive, setSubCatFilterActive] = useState(null);
//   const [rolesActive, setRolesActive] = useState(null);

//   const [selectSubCategory, setSelectSubCategory] = useState("");
//   const [selectRoles, setSelectRoles] = useState("");
//   const [selectName, setSelectName] = useState("");
//   const [location, setLocation] = useState("");

//   console.log("location", location)

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
//         .then((res) => {
//           // console.log("res", res.data.Subcategories);
//           setSubCategory(res.data.Subcategories);
//         })
//         .catch((error) => {
//           console.log("err");
//         });
//     }
//   }, [categoryId]);

//   const handleCheckedSubCategory = (index, SubCategoryId) => {
//     setSubCatFilterActive(index);

//     axios
//       .get(`${API_BASE_URL}/getjobrolebysubcategory/${SubCategoryId}`)
//       .then((res) => {
//         setRoles(res.data.JobRoles);
//         setSelectName(res.data?.description);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleCheckedRoles = (index) => {
//     setRolesActive(index);
//   };

//   useEffect(() => {
//     if (subCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
//         .then((res) => {
//           console.log("description", res.data);
//           setRoles(res.data.JobRoles);
//           setSelectName(res.data?.description);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [subCategoryId]);

//   useEffect(() => {
//     if (selectSubCategory || selectRoles) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory}&name=${selectName}&value=${selectRoles}&Location=${location}`
//         )
//         .then((res) => {
//           setAds(res.data.Properties);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [selectSubCategory, selectRoles, location]);

//   return (
//     <div className="">
//       <h3>Filters</h3>

//       {subCategory.length === 0 ? (
//         <p className="text-danger">No Filters...</p>
//       ) : (
//         <>
//           <div className="filters-options row my-2">
//             {subCategory.length > 0 && (
//               <div className="my-2 col-lg-12 col-md-6 col-sm-6">
//                 <label className="filter-title" style={{backgroundColor:"red"}}>Sub Category</label>
//                 {subCategory.map((filter, index) => (
//                   <div
//                     className="filters"
//                     key={index}
//                     onClick={() => setSelectSubCategory(filter?.SubCategory)}
//                   >
//                     <input
//                       type="checkbox"
//                       id={`filt-${index}`}
//                       checked={subCatFilterActive === index}
//                     />
//                     <label
//                       htmlFor={`filt-${index}`}
//                       onClick={() =>
//                         handleCheckedSubCategory(index, filter?._id)
//                       }
//                     >
//                       {filter?.SubCategory}
//                     </label>
//                   </div>
//                 ))}
//               </div>
//             )}

//             {roles.length > 0 && (
//               <div className="my-2 col-lg-12 col-md-6 col-sm-6">
//                 <label className="filter-title" style={{backgroundColor:"red"}}>{selectName}</label>
//                 {roles.map((filter, index) => (
//                   <div
//                     className="filters"
//                     key={index}
//                     onClick={() => setSelectRoles(filter)}
//                   >
//                     <input
//                       type="checkbox"
//                       id={`filt-${index}`}
//                       checked={rolesActive === index}
//                     />
//                     <label
//                       htmlFor={`filt-${index}`}
//                       onClick={() => handleCheckedRoles(index)}
//                     >
//                       {filter}
//                     </label>

//                   </div>
//                 ))}
//               </div>
//             )}

// {
  /* <div className="my-2 col-lg-12 col-md-6 col-sm-6">
              <label className="filter-title">Location</label>
              <input
                className="filter-search-input"
                type="search"
                placeholder="Search location..."
                name=""
                id="" 
                onChange={(e) => setLocation(e.target.value)}
              />
            </div> */
// }

//           </div>
//         </>
//       )}
//     </div>
//   );
// }
// export default Filters;


// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../utils";
// import AngleDown from "../../assets/images/angle-down-svg.svg";
// import "./Filters.css"; // Import the CSS file

// function Filters({ currentSubCategory, setAds }) {
//   const categoryId = currentSubCategory?.Main_Category?._id;
//   const subCategoryId = currentSubCategory?._id;
//   const [subCategory, setSubCategory] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [subCatFilterActive, setSubCatFilterActive] = useState([]);
//   const [rolesActive, setRolesActive] = useState([]);

//   const [selectSubCategory, setSelectSubCategory] = useState([]);
//   const [selectRoles, setSelectRoles] = useState([]);
//   const [selectName, setSelectName] = useState("");
//   const [location, setLocation] = useState("");
//   const [activeFilter, setActiveFilter] = useState(null); // For accordion behavior

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
//         .then((res) => {
//           setSubCategory(res.data.Subcategories);
//         })
//         .catch((error) => {
//           console.log("err");
//         });
//     }
//   }, [categoryId]);

//   const handleCheckedSubCategory = (index, SubCategoryId) => {


    
//     const subCategoryName=SubCategoryId?.SubCategory;
//     const subCategoryIdByFilter=SubCategoryId?._id;
//     console.log("handleSubcategory------->",SubCategoryId)
//     console.log("handleSubcategory by index------->",index)
//     const updatedSubCatFilterActive = [...subCatFilterActive];
//     if (updatedSubCatFilterActive.includes(index)) {
//       updatedSubCatFilterActive.splice(updatedSubCatFilterActive.indexOf(index), 1);
//     } else {
//       updatedSubCatFilterActive.push(index);
//     }
//     setSubCatFilterActive(updatedSubCatFilterActive);

//     const updatedSelectSubCategory = [...selectSubCategory];
//     // if (updatedSelectSubCategory.includes(SubCategoryId)) {
//     //   updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(SubCategoryId), 1);
//     // } else {
//     //   updatedSelectSubCategory.push(SubCategoryId);
//     // }
//     if (updatedSelectSubCategory.includes(subCategoryName)) {
//       updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(subCategoryName), 1);
//     } else {
//       updatedSelectSubCategory.push(subCategoryName);
//     }
//     setSelectSubCategory(updatedSelectSubCategory);

//     axios
//       // .get(`${API_BASE_URL}/getjobrolebysubcategory/${SubCategoryId}`)
//       .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryIdByFilter}`)
//       .then((res) => {
//         setRoles(res.data.JobRoles);
//         setSelectName(res.data?.description);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleCheckedRoles = (index, role) => {
//     const updatedRolesActive = [...rolesActive];
//     if (updatedRolesActive.includes(index)) {
//       updatedRolesActive.splice(updatedRolesActive.indexOf(index), 1);
//     } else {
//       updatedRolesActive.push(index);
//     }
//     setRolesActive(updatedRolesActive);

//     const updatedSelectRoles = [...selectRoles];
//     if (updatedSelectRoles.includes(role)) {
//       updatedSelectRoles.splice(updatedSelectRoles.indexOf(role), 1);
//     } else {
//       updatedSelectRoles.push(role);
//     }
//     setSelectRoles(updatedSelectRoles);
//   };

//   useEffect(() => {
//     if (subCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
//         .then((res) => {
//           console.log("description", res.data);
//           setRoles(res.data.JobRoles);
//           setSelectName(res.data?.description);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [subCategoryId]);

//   useEffect(() => {
//     if (selectSubCategory.length || selectRoles.length) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory.join(",")}&name=${selectName}&value=${selectRoles.join(",")}&Location=${location}`
//         )
//         .then((res) => {
//           setAds(res.data.Properties);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [selectSubCategory, selectRoles, location]);

//   const handleFilterToggle = (filter) => {
//     setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
//   };

//   return (
//     <div className="filters-container" style={{width:"100%",margin:"0px"}}>
//       <h3>Filters</h3>

//       {subCategory.length === 0 ? (
//         <p className="text-danger">No Filters...</p>
//       ) : (
//         <>
//           <div className="filters-options" style={{width: "inherit",scrollbarWidth:"none"}}  >
//             {subCategory.length > 0 && (
//               <div className="filter-group border border-2 rounded-2" style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
//                 <label
//                   className="filter-title text-center mt-3" 
//                   style={{width:"100%",}}
//                   onClick={() => handleFilterToggle("SubCategory")}
//                 >
//                   Sub Category
//                   {/* <img
//                     src={AngleDown}
//                     alt=""
//                     width={"10%"}
//                     style={{
//                       transform: activeFilter === "SubCategory" ? "rotate(180deg)" : "rotate(0deg)",
//                       transition: "transform 0.3s ease"
//                     }}
//                   /> */}
//                 </label>
// <hr />
//                 {/* {activeFilter === "SubCategory" && */}
//                   <div className="filter-list m-auto" >
//                     {subCategory.map((filter, index) => (
//                       <div className="filters" key={index}>
//                         <input
//                           type="checkbox"
//                           id={`filt-${index}`}
//                           checked={subCatFilterActive.includes(index)}
//                           // onChange={() => handleCheckedSubCategory(index, filter?._id)}
//                           onChange={() => handleCheckedSubCategory(index, filter)}
//                         />
//                         <label htmlFor={`filt-${index}`} style={{fontSize:"15px"}}>
//                           {filter?.SubCategory}
//                         </label>
//                       </div>
//                     ))}
//                   </div>
//                 {/* } */}
//               </div>
//             )}

//               {roles.length > 0 && (
//                 <div   className="filter-group border border-2 rounded-2" style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
//                   <label
//                     className="filter-title text-center mt-3"
//                     style={{width:"100%"}}
//                     onClick={() => handleFilterToggle("Roles")}
//                   >
//                     {selectName}
//                     {/* <img
//                       src={AngleDown}
//                       alt=""
//                       width={"10%"}
//                       style={{
//                         transform: activeFilter === "Roles" ? "rotate(180deg)" : "rotate(0deg)",
//                         transition: "transform 0.3s ease"
//                       }}
//                     /> */}
//                   </label>
//                   <hr />
              
//                     <div className="filter-list" >
//                       {roles.map((filter, index) => (
//                         <div className="filters m-auto" key={index} >
//                           <input
//                             type="checkbox"
//                             id={`role-${index}`}
//                             checked={rolesActive.includes(index)}
//                             onChange={() => handleCheckedRoles(index, filter)}
//                           />
//                           <label htmlFor={`role-${index}`}>
//                             {filter}
//                           </label>
//                         </div>
//                       ))}
//                     </div>
                
//                 </div>
//               )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Filters;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../utils";
// import AngleDown from "../../assets/images/angle-down-svg.svg";
// import { FaFilter, FaSearch } from 'react-icons/fa'; // Importing search icon from react-icons
// import "./Filters.css"; // Import the CSS file

// function Filters({ currentSubCategory, setAds }) {
//   const categoryId = currentSubCategory?.Main_Category?._id;
//   const subCategoryId = currentSubCategory?._id;
//   const [subCategory, setSubCategory] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [subCatFilterActive, setSubCatFilterActive] = useState([]);
//   const [rolesActive, setRolesActive] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState([]);
//   const [selectRoles, setSelectRoles] = useState([]);
//   const [selectName, setSelectName] = useState("");
//   const [location, setLocation] = useState("");
//   const [activeFilter, setActiveFilter] = useState(null); // For accordion behavior
//   const [searchTerm, setSearchTerm] = useState(""); // For role search functionality
//   const filteredRoles = roles.filter(role =>
//     role.toLowerCase().includes(searchTerm.toLowerCase())
//   ).slice(0, 3);
//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
//         .then((res) => {
//           setSubCategory(res.data.Subcategories);
//         })
//         .catch((error) => {
//           console.log("err");
//         });
//     }
//   }, [categoryId]);

//   const handleCheckedSubCategory = (index, SubCategoryId) => {
//     const subCategoryName = SubCategoryId?.SubCategory;
//     const subCategoryIdByFilter = SubCategoryId?._id;
//     console.log("handleSubcategory------->", SubCategoryId);
//     console.log("handleSubcategory by index------->", index);
//     const updatedSubCatFilterActive = [...subCatFilterActive];
//     if (updatedSubCatFilterActive.includes(index)) {
//       updatedSubCatFilterActive.splice(updatedSubCatFilterActive.indexOf(index), 1);
//     } else {
//       updatedSubCatFilterActive.push(index);
//     }
//     setSubCatFilterActive(updatedSubCatFilterActive);

//     const updatedSelectSubCategory = [...selectSubCategory];
//     if (updatedSelectSubCategory.includes(subCategoryName)) {
//       updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(subCategoryName), 1);
//     } else {
//       updatedSelectSubCategory.push(subCategoryName);
//     }
//     setSelectSubCategory(updatedSelectSubCategory);

//     axios
//       .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryIdByFilter}`)
//       .then((res) => {
//         setRoles(res.data.JobRoles);
//         setSelectName(res.data?.description);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleCheckedRoles = (index, role) => {
//     const updatedRolesActive = [...rolesActive];
//     if (updatedRolesActive.includes(index)) {
//       updatedRolesActive.splice(updatedRolesActive.indexOf(index), 1);
//     } else {
//       updatedRolesActive.push(index);
//     }
//     setRolesActive(updatedRolesActive);

//     const updatedSelectRoles = [...selectRoles];
//     if (updatedSelectRoles.includes(role)) {
//       updatedSelectRoles.splice(updatedSelectRoles.indexOf(role), 1);
//     } else {
//       updatedSelectRoles.push(role);
//     }
//     setSelectRoles(updatedSelectRoles);
//   };

//   useEffect(() => {
//     if (subCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
//         .then((res) => {
//           console.log("description", res.data);
//           setRoles(res.data.JobRoles);
//           setSelectName(res.data?.description);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [subCategoryId]);

//   useEffect(() => {
//     if (selectSubCategory.length || selectRoles.length) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory.join(",")}&name=${selectName}&value=${selectRoles.join(",")}&Location=${location}`
//         )
//         .then((res) => {
//           setAds(res.data.Properties);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [selectSubCategory, selectRoles, location]);

//   const handleFilterToggle = (filter) => {
//     setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
//   };

  
//   return (
//     <div className="filters-container mb-5 mt-2 border border-2 rounded-2" style={{ width: "100%", margin: "0px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//       <h5 className="m-auto mt-2 mb-2"> Filters <FaFilter /></h5>

//       {subCategory.length === 0 ? (
//         <p className="text-danger">No Filters...</p>
//       ) : (
//         <>
//           <div className="filters-options m-auto" style={{ width: "", scrollbarWidth: "none" ,fontSize:"13px"}}>
//             {subCategory.length > 0 && (
//               <div className="filter-group border border-2 rounded-2" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("SubCategory")}
//                 >
//                   Sub Category
//                 </label>
//                 <hr />
//                 <div className="filter-list m-auto">
//                   {subCategory.map((filter, index) => (
//                     <div className="filters" key={index}>
//                       <input
//                         type="checkbox"
//                         id={`filt-${index}`}
//                         checked={subCatFilterActive.includes(index)}
//                         onChange={() => handleCheckedSubCategory(index, filter)}
//                       />
//                       <label htmlFor={`filt-${index}`} style={{ fontSize: "15px" }}>
//                         {filter?.SubCategory}
//                       </label>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}

//             {roles.length > 0 && (
//               <div className="filter-group border border-2 rounded-2" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("Roles")}
//                 >
//                   {selectName}
//                 </label>
//                 <hr />
//                 <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{boxShadow:" rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
//                   <input
//                     type="text"
//                     placeholder={`Search ${selectName}..`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="search-input"
//                     style={{outline:"none",textIndent:5}}
//                   />
                

//                   <FaSearch className="search-icon  " />
            
//                 </div>
//                 <div className="filter-list m-auto mt-3">
//                   {filteredRoles.map((filter, index) => (
//                     <div className="filters " key={index}>
//                       <input
//                         type="checkbox"
//                         id={`role-${index}`}
//                         checked={rolesActive.includes(index)}
//                         onChange={() => handleCheckedRoles(index, filter)}
//                       />
//                       <label htmlFor={`role-${index}`} style={{ fontSize: "15px" }}>
//                         {filter}
//                       </label>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Filters;


// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// for trial 
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../utils";
// import { FaFilter, FaSearch, FaTimes } from 'react-icons/fa'; // Importing search and cancel icons from react-icons
// import "./Filters.css"; // Import the CSS file

// function Filters({ currentSubCategory, setAds }) {
//   const categoryId = currentSubCategory?.Main_Category?._id;
//   const subCategoryId = currentSubCategory?._id;
//   const [subCategory, setSubCategory] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [subCatFilterActive, setSubCatFilterActive] = useState([]);
//   const [rolesActive, setRolesActive] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState([]);
//   const [selectRoles, setSelectRoles] = useState([]);
//   const [selectName, setSelectName] = useState("");
//   const [location, setLocation] = useState("");
//   const [activeFilter, setActiveFilter] = useState(null); // For accordion behavior
//   const [searchTerm, setSearchTerm] = useState(""); // For role search functionality
//   const [subCatSearchTerm, setSubCatSearchTerm] = useState(""); // For subcategory search functionality

//   // Filter roles based on search term and limit to 4 items
//   const filteredRoles = roles
//     .filter(role => role.toLowerCase().includes(searchTerm.toLowerCase()))
//     .slice(0, 3);

//   // Filter subcategories based on search term and limit to 4 items
//   const filteredSubCategories = subCategory
//     .filter(cat => cat.SubCategory.toLowerCase().includes(subCatSearchTerm.toLowerCase()))
//     .slice(0, 3);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
//         .then((res) => {
//           setSubCategory(res.data.Subcategories);
//         })
//         .catch((error) => {
//           console.log("err", error);
//         });
//     }
//   }, [categoryId]);

//   const handleCheckedSubCategory = (index, SubCategoryId) => {
//     const subCategoryName = SubCategoryId?.SubCategory;
//     const subCategoryIdByFilter = SubCategoryId?._id;
//     const updatedSubCatFilterActive = [...subCatFilterActive];
//     if (updatedSubCatFilterActive.includes(index)) {
//       updatedSubCatFilterActive.splice(updatedSubCatFilterActive.indexOf(index), 1);
//     } else {
//       updatedSubCatFilterActive.push(index);
//     }
//     setSubCatFilterActive(updatedSubCatFilterActive);

//     const updatedSelectSubCategory = [...selectSubCategory];
//     if (updatedSelectSubCategory.includes(subCategoryName)) {
//       updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(subCategoryName), 1);
//     } else {
//       updatedSelectSubCategory.push(subCategoryName);
//     }
//     setSelectSubCategory(updatedSelectSubCategory);

//     axios
//       .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryIdByFilter}`)
//       .then((res) => {
//         setRoles(res.data.JobRoles);
//         setSelectName(res.data?.description);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleCheckedRoles = (index, role) => {
//     const updatedRolesActive = [...rolesActive];
//     if (updatedRolesActive.includes(index)) {
//       updatedRolesActive.splice(updatedRolesActive.indexOf(index), 1);
//     } else {
//       updatedRolesActive.push(index);
//     }
//     setRolesActive(updatedRolesActive);

//     const updatedSelectRoles = [...selectRoles];
//     if (updatedSelectRoles.includes(role)) {
//       updatedSelectRoles.splice(updatedSelectRoles.indexOf(role), 1);
//     } else {
//       updatedSelectRoles.push(role);
//     }
//     setSelectRoles(updatedSelectRoles);
//   };

//   useEffect(() => {
//     if (subCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
//         .then((res) => {
//           setRoles(res.data.JobRoles);
//           setSelectName(res.data?.description);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [subCategoryId]);

//   useEffect(() => {
//     if (selectSubCategory.length || selectRoles.length) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory.join(",")}&name=${selectName}&value=${selectRoles.join(",")}&Location=${location}`
//         )
//         .then((res) => {
//           setAds(res.data.Properties);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [selectSubCategory, selectRoles, location]);

//   const handleFilterToggle = (filter) => {
//     setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
//   };

//   const handleClearAll = () => {
//     setSelectSubCategory([]);
//     setSelectRoles([]);
//     setRolesActive([]);
//     setSubCatFilterActive([]);
//     setSearchTerm("");
//     setSubCatSearchTerm("");
//     setLocation("");
//     setAds([]);
//   };

//   const handleRemoveSelectedSubCategory = (subCategory) => {
//     const updatedSelectSubCategory = selectSubCategory.filter(item => item !== subCategory);
//     setSelectSubCategory(updatedSelectSubCategory);
//     // You may need to update the selected roles as well
//   };

//   const handleRemoveSelectedRole = (role) => {
//     const updatedSelectRoles = selectRoles.filter(item => item !== role);
//     setSelectRoles(updatedSelectRoles);
//   };

//   return (
//     <div className="filters-container mb-5 mt-2 border border-2 rounded-2" style={{ width: "100%", margin: "0px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//       <div className="filters-header d-flex   mb-2">
//         <h5 className="ms-2 mt-2 mb-2"> Filters <FaFilter /></h5>
//         <button className="btn btn-outline-primary ms-5 mt-2 " onClick={handleClearAll}>Clear All</button>
//       </div>

//       {selectSubCategory.length > 0 || selectRoles.length > 0 ? (
//         <div className="selected-filters mb-3">
//           {selectSubCategory.map((subCategory, index) => (
//             <span key={index} className="selected-filter-tag">
//               {subCategory}
//               <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedSubCategory(subCategory)} />
//             </span>
//           ))}
//           {selectRoles.map((role, index) => (
//             <span key={index} className="selected-filter-tag">
//               {role}
//               <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedRole(role)} />
//             </span>
//           ))}
//         </div>
//       ) : null}

//       {subCategory.length === 0 ? (
//         <p className="text-danger">No Filters...</p>
//       ) : (
//         <>
//           <div className="filters-options m-auto" style={{ width: "", scrollbarWidth: "none", fontSize: "13px" }}>
            
//             {/* Subcategory Filter */}
//             {subCategory.length > 0 && (
//               <div className="filter-group border border-2 rounded-2" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("SubCategory")}
//                 >
//                   Sub Category
//                 </label>
//                 <hr />
//                 <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search Subcategory..."
//                     value={subCatSearchTerm}
//                     onChange={(e) => setSubCatSearchTerm(e.target.value)}
//                     className="search-input"
//                     style={{ outline: "none", textIndent: 5 }}
//                   />
//                   <FaSearch className="search-icon" />
//                 </div>
//                 <div className="filter-list m-auto mt-3">
//                   {filteredSubCategories.map((filter, index) => (
//                     <div className="filters" key={index}>
//                       <input
//                         type="checkbox"
//                         id={`subcat-${index}`}
//                         checked={subCatFilterActive.includes(index)}
//                         onChange={() => handleCheckedSubCategory(index, filter)}
//                       />
//                       <label htmlFor={`subcat-${index}`} style={{ fontSize: "15px" }}>
//                         {filter?.SubCategory}
//                       </label>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}

//             {/* Role Filter */}
//             {roles.length > 0 && (
//               <div className="filter-group border border-2 rounded-2 mb-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("Roles")}
//                 >
//                   {selectName}
//                 </label>
//                 <hr />
//                 <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                   <input
//                     type="text"
//                     placeholder={`Search ${selectName}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="search-input"
//                     style={{ outline: "none", textIndent: 5 }}
//                   />
//                   <FaSearch className="search-icon" />
//                 </div>
//                 <div className="filter-list m-auto mt-3">
//                   {filteredRoles.map((filter, index) => (
//                     <div className="filters" key={index}>
//                       <input
//                         type="checkbox"
//                         id={`role-${index}`}
//                         checked={rolesActive.includes(index)}
//                         onChange={() => handleCheckedRoles(index, filter)}
//                       />
//                       <label htmlFor={`role-${index}`} style={{ fontSize: "15px" }}>
//                         {filter}
//                       </label>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Filters;
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../utils";
// import { FaFilter, FaSearch, FaTimes } from 'react-icons/fa'; // Importing search, cancel, and filter icons from react-icons
// import "./Filters.css"; // Import the CSS file

// function Filters({ currentSubCategory, setAds }) {
//   const categoryId = currentSubCategory?.Main_Category?._id;
//   const subCategoryId = currentSubCategory?._id;
//   const [subCategory, setSubCategory] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [subCatFilterActive, setSubCatFilterActive] = useState([]);
//   const [rolesActive, setRolesActive] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState([]);
//   const [selectRoles, setSelectRoles] = useState([]);
//   const [selectName, setSelectName] = useState("");
//   const [location, setLocation] = useState("");
//   const [activeFilter, setActiveFilter] = useState(false); // For accordion behavior
//   const [searchTerm, setSearchTerm] = useState(""); // For role search functionality
//   const [subCatSearchTerm, setSubCatSearchTerm] = useState(""); // For subcategory search functionality

//   // Filter roles based on search term and limit to 4 items
//   const filteredRoles = roles
//     .filter(role => role.toLowerCase().includes(searchTerm.toLowerCase()))
//     .slice(0, 3);

//   // Filter subcategories based on search term and limit to 4 items
//   const filteredSubCategories = subCategory
//     .filter(cat => cat.SubCategory.toLowerCase().includes(subCatSearchTerm.toLowerCase()))
//     .slice(0, 3);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
//         .then((res) => {
//           setSubCategory(res.data.Subcategories);
//         })
//         .catch((error) => {
//           console.log("err", error);
//         });
//     }
//   }, [categoryId]);

//   const handleCheckedSubCategory = (index, SubCategoryId) => {
//     const subCategoryName = SubCategoryId?.SubCategory;
//     const subCategoryIdByFilter = SubCategoryId?._id;
//     const updatedSubCatFilterActive = [...subCatFilterActive];
//     if (updatedSubCatFilterActive.includes(index)) {
//       updatedSubCatFilterActive.splice(updatedSubCatFilterActive.indexOf(index), 1);
//     } else {
//       updatedSubCatFilterActive.push(index);
//     }
//     setSubCatFilterActive(updatedSubCatFilterActive);

//     const updatedSelectSubCategory = [...selectSubCategory];
//     if (updatedSelectSubCategory.includes(subCategoryName)) {
//       updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(subCategoryName), 1);
//     } else {
//       updatedSelectSubCategory.push(subCategoryName);
//     }
//     setSelectSubCategory(updatedSelectSubCategory);

//     axios
//       .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryIdByFilter}`)
//       .then((res) => {
//         setRoles(res.data.JobRoles);
//         setSelectName(res.data?.description);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   const handleCheckedRoles = (index, role) => {
//     const updatedRolesActive = [...rolesActive];
//     if (updatedRolesActive.includes(index)) {
//       updatedRolesActive.splice(updatedRolesActive.indexOf(index), 1);
//     } else {
//       updatedRolesActive.push(index);
//     }
//     setRolesActive(updatedRolesActive);

//     const updatedSelectRoles = [...selectRoles];
//     if (updatedSelectRoles.includes(role)) {
//       updatedSelectRoles.splice(updatedSelectRoles.indexOf(role), 1);
//     } else {
//       updatedSelectRoles.push(role);
//     }
//     setSelectRoles(updatedSelectRoles);
//   };

//   useEffect(() => {
//     if (subCategoryId) {
//       axios
//         .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
//         .then((res) => {
//           setRoles(res.data.JobRoles);
//           setSelectName(res.data?.description);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [subCategoryId]);

//   useEffect(() => {
//     if (selectSubCategory.length || selectRoles.length) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory.join(",")}&name=${selectName}&value=${selectRoles.join(",")}&Location=${location}`
//         )
//         .then((res) => {
//           setAds(res.data.Properties);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [selectSubCategory, selectRoles, location]);

//   const handleFilterToggle = (filter) => {
//     setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
//   };

//   const handleClearAll = () => {
//     setSelectSubCategory([]);
//     setSelectRoles([]);
//     setRolesActive([]);
//     setSubCatFilterActive([]);
//     setSearchTerm("");
//     setSubCatSearchTerm("");
//     setLocation("");
//     setAds([]);
//   };

//   const handleRemoveSelectedSubCategory = (subCategory) => {
//     const updatedSelectSubCategory = selectSubCategory.filter(item => item !== subCategory);
//     setSelectSubCategory(updatedSelectSubCategory);
//     // You may need to update the selected roles as well
//   };

//   const handleRemoveSelectedRole = (role) => {
//     const updatedSelectRoles = selectRoles.filter(item => item !== role);
//     setSelectRoles(updatedSelectRoles);
//   };

//   return (
//     <div className="filters-container mb-5 mt-2 border border-2 rounded-2" style={{ width: "100%", margin: "0px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>

//         <h5 className="m-auto mt-2 mb-4"> Filters <FaFilter /></h5>
    
//       {selectSubCategory.length > 0 || selectRoles.length > 0 ? (
//         <div className="selected-filters mb-3 m-auto container">
//           {selectSubCategory.map((subCategory, index) => (
//             <span key={index} className="selected-filter-tag " style={{fontSize:"12px"}}>
//               {subCategory}
//               <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedSubCategory(subCategory)} />
//             </span>
//           ))}
//           {selectRoles.map((role, index) => (
//             <span key={index} className="selected-filter-tag" style={{fontSize:"12px"}}>
//               {role}
//               <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedRole(role)} />
//             </span>
//           ))}
//         </div>
//       ) : null}

//       {subCategory.length === 0 ? (
//         <p className="text-danger">No Filters...</p>
//       ) : (
//         <>
//           <div className="filters-options m-auto" style={{ width: " 90%", scrollbarWidth: "none", fontSize: "13px" }}>
            
//             {/* Subcategory Filter */}
//             {subCategory.length > 0 && (
//               <div className="filter-group border border-2 rounded-2" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3 mb-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("SubCategory")}
//                 >
//                   Sub Category
//                 </label>
//                 {/* <hr /> */}
//                 {activeFilter === "SubCategory" && (
//                   <>
//                     <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                       <input
//                         type="text"
//                         placeholder="Search Subcategory..."
//                         value={subCatSearchTerm}
//                         onChange={(e) => setSubCatSearchTerm(e.target.value)}
//                         className="search-input"
//                         style={{ outline: "none", textIndent: 5 }}
//                       />
//                       <FaSearch className="search-icon" />
//                     </div>
//                     <div className="filter-list m-auto mt-3">
//                       {filteredSubCategories.map((filter, index) => (
//                         <div className="filters" key={index}>
//                           <input
//                             type="checkbox"
//                             id={`subcat-${index}`}
//                             checked={subCatFilterActive.includes(index)}
//                             onChange={() => handleCheckedSubCategory(index, filter)}
//                           />
//                           <label htmlFor={`subcat-${index}`} style={{ fontSize: "15px" }}>
//                             {filter?.SubCategory}
//                           </label>
//                         </div>
//                       ))}
//                     </div>
//                   </>
//                 )}
//               </div>
//             )}

//             {/* Role Filter */}
//             {roles.length > 0 && (
//               <div className="filter-group border border-2 rounded-2 mb-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                 <label
//                   className="filter-title text-center mt-3 mb-3"
//                   style={{ width: "100%" }}
//                   onClick={() => handleFilterToggle("Roles")}
//                 >
//                   {selectName}
//                 </label>
//                 {/* <hr /> */}
//                 {activeFilter === "Roles" && (
//                   <>
//                     <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//                       <input
//                         type="text"
//                         placeholder={`Search ${selectName}...`}
//                         value={searchTerm}
//                         onChange={(e) => setSearchTerm(e.target.value)}
//                         className="search-input"
//                         style={{ outline: "none", textIndent: 5 }}
//                       />
//                       <FaSearch className="search-icon" />
//                     </div>
//                     <div className="filter-list m-auto mt-3">
//                       {filteredRoles.map((filter, index) => (
//                         <div className="filters" key={index}>
//                           <input
//                             type="checkbox"
//                             id={`role-${index}`}
//                             checked={rolesActive.includes(index)}
//                             onChange={() => handleCheckedRoles(index, filter)}
//                           />
//                           <label htmlFor={`role-${index}`} style={{ fontSize: "15px" }}>
//                             {filter}
//                           </label>
//                         </div>
//                       ))}
//                     </div>
//                   </>
//                 )}
//               </div>
//             )}
//           </div>
//         </>
//       )}
//           <button className="btn btn-outline-danger mb-4 m-auto" onClick={handleClearAll}>Clear All</button>
//     </div>
//   );
// }

// export default Filters;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { FaFilter, FaSearch, FaTimes } from 'react-icons/fa'; // Importing search, cancel, and filter icons from react-icons
import "./Filters.css"; // Import the CSS file

function Filters({ currentSubCategory, setAds }) {
  const categoryId = currentSubCategory?.Main_Category?._id;
  const subCategoryId = currentSubCategory?._id;
  const [subCategory, setSubCategory] = useState([]);
  const [roles, setRoles] = useState([]);
  const [subCatFilterActive, setSubCatFilterActive] = useState([]);
  const [rolesActive, setRolesActive] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);
  const [selectName, setSelectName] = useState("");
  const [location, setLocation] = useState("");
  const [activeFilter, setActiveFilter] = useState("Both"); // For accordion behavior
  const [searchTerm, setSearchTerm] = useState(""); // For role search functionality
  const [subCatSearchTerm, setSubCatSearchTerm] = useState(""); // For subcategory search functionality

  // Filter roles based on search term and limit to 4 items
  const filteredRoles = roles
    .filter(role => role.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(0, 3);

  // Filter subcategories based on search term and limit to 4 items
  const filteredSubCategories = subCategory
    .filter(cat => cat.SubCategory.toLowerCase().includes(subCatSearchTerm.toLowerCase()))
    .slice(0, 3);

  useEffect(() => {
    if (categoryId) {
      axios
        .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`)
        .then((res) => {
          setSubCategory(res.data.Subcategories);
        })
        .catch((error) => {
          console.log("err", error);
        });
    }
  }, [categoryId]);

  const handleCheckedSubCategory = (index, SubCategoryId) => {
    const subCategoryName = SubCategoryId?.SubCategory;
    const subCategoryIdByFilter = SubCategoryId?._id;
    const updatedSubCatFilterActive = [...subCatFilterActive];
    if (updatedSubCatFilterActive.includes(index)) {
      updatedSubCatFilterActive.splice(updatedSubCatFilterActive.indexOf(index), 1);
    } else {
      updatedSubCatFilterActive.push(index);
    }
    setSubCatFilterActive(updatedSubCatFilterActive);

    const updatedSelectSubCategory = [...selectSubCategory];
    if (updatedSelectSubCategory.includes(subCategoryName)) {
      updatedSelectSubCategory.splice(updatedSelectSubCategory.indexOf(subCategoryName), 1);
    } else {
      updatedSelectSubCategory.push(subCategoryName);
    }
    setSelectSubCategory(updatedSelectSubCategory);

    axios
      .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryIdByFilter}`)
      .then((res) => {
        setRoles(res.data.JobRoles);
        setSelectName(res.data?.description);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleCheckedRoles = (index, role) => {
    const updatedRolesActive = [...rolesActive];
    if (updatedRolesActive.includes(index)) {
      updatedRolesActive.splice(updatedRolesActive.indexOf(index), 1);
    } else {
      updatedRolesActive.push(index);
    }
    setRolesActive(updatedRolesActive);

    const updatedSelectRoles = [...selectRoles];
    if (updatedSelectRoles.includes(role)) {
      updatedSelectRoles.splice(updatedSelectRoles.indexOf(role), 1);
    } else {
      updatedSelectRoles.push(role);
    }
    setSelectRoles(updatedSelectRoles);
  };

  useEffect(() => {
    if (subCategoryId) {
      axios
        .get(`${API_BASE_URL}/getjobrolebysubcategory/${subCategoryId}`)
        .then((res) => {
          setRoles(res.data.JobRoles);
          setSelectName(res.data?.description);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [subCategoryId]);

  useEffect(() => {
    if (selectSubCategory.length || selectRoles.length) {
      axios
        .get(
          `${API_BASE_URL}/user/filtersearch?PostSubCategory=${selectSubCategory.join(",")}&name=${selectName}&value=${selectRoles.join(",")}&Location=${location}`
        )
        .then((res) => {
          setAds(res.data.Properties);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectSubCategory, selectRoles, location]);

  const handleFilterToggle = (filter) => {
    setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };

  const handleClearAll = () => {
    setSelectSubCategory([]);
    setSelectRoles([]);
    setRolesActive([]);
    setSubCatFilterActive([]);
    setSearchTerm("");
    setSubCatSearchTerm("");
    setLocation("");
    setAds([]);
  };

  const handleRemoveSelectedSubCategory = (subCategory) => {
    const updatedSelectSubCategory = selectSubCategory.filter(item => item !== subCategory);
    setSelectSubCategory(updatedSelectSubCategory);
    // You may need to update the selected roles as well
  };

  const handleRemoveSelectedRole = (role) => {
    const updatedSelectRoles = selectRoles.filter(item => item !== role);
    setSelectRoles(updatedSelectRoles);
  };

  return (
    <div className="filters-container p-0 border border-2 rounded-2  " style={{ width: "90%", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>

        <h6 className="m-auto mt-2 mb-4"> Filters <FaFilter /></h6>
    
      {selectSubCategory.length > 0 || selectRoles.length > 0 ? (
        <div className="selected-filters mb-3 m-auto container">
          {selectSubCategory.map((subCategory, index) => (
            <span key={index} className="selected-filter-tag " style={{fontSize:"12px"}}>
              {subCategory}
              <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedSubCategory(subCategory)} />
            </span>
          ))}
          {selectRoles.map((role, index) => (
            <span key={index} className="selected-filter-tag" style={{fontSize:"12px"}}>
              {role}
              <FaTimes className="cancel-icon" onClick={() => handleRemoveSelectedRole(role)} />
            </span>
          ))}
        </div>
      ) : null}

      {subCategory.length === 0 ? (
        <p className="text-danger">No Filters...</p>
      ) : (
        <>
          <div className="filters-options m-auto" style={{ width: " 90%", scrollbarWidth: "none", fontSize: "13px" }}>
            
            {/* Subcategory Filter */}
            {subCategory.length > 0 && (
              <div className="filter-group border border-2 rounded-2 w-100" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                <label
                  className="filter-title text-center mt-3 mb-3"
                  style={{ width: "100%" }}
                  onClick={() => handleFilterToggle("SubCategory")}
                >
                  Sub Category
                </label>
                {/* <hr /> */}
                {activeFilter === "Both" || activeFilter === "SubCategory" ? (
                  <>
                    <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",width:"90%" }}>
                      <input
                        type="text"
                        placeholder="Search Subcategory."
                        value={subCatSearchTerm}
                        onChange={(e) => setSubCatSearchTerm(e.target.value)}
                        className="search-input"
                        style={{ outline: "none", textIndent: 1,fontSize:"9px"}}
                      />
                      <FaSearch className="search-icon" />
                    </div>
                    <div className="filter-list m-auto mt-3">
                      {filteredSubCategories.map((filter, index) => (
                        <div className="filters" key={index}>
                          <input
                            type="checkbox"
                            id={`subcat-${index}`}
                            checked={subCatFilterActive.includes(index)}
                            onChange={() => handleCheckedSubCategory(index, filter)}
                          />
                          <label htmlFor={`subcat-${index}`} style={{ fontSize: "12px" }}>
                            {filter?.SubCategory}
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            )}

            {/* Role Filter */}
            {roles.length > 0 && (
              <div className="filter-group border border-2 w-100 rounded-2 mb-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",width:"90%" }}>
                <label
                  className="filter-title text-center mt-3 mb-3"
                  style={{ width: "100%" }}
                  onClick={() => handleFilterToggle("Roles")}
                >
                  {selectName}
                </label>
                {/* <hr /> */}
                {activeFilter === "Both" || activeFilter === "Roles" ? (
                  <>
                    <div className="filter-search ms-2 me-2 p-2 rounded-3" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                      <input
                        type="text"
                        placeholder={`Search ${selectName}.`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                        style={{ outline: "none", textIndent: 1,fontSize:"10px" }}
                      />
                      <FaSearch className="search-icon" />
                    </div>
                    <div className="filter-list ms-3 mt-3">
                      {filteredRoles.map((filter, index) => (
                        <div className="filters" key={index}>
                          <input
                            type="checkbox"
                            id={`role-${index}`}
                            checked={rolesActive.includes(index)}
                            onChange={() => handleCheckedRoles(index, filter)}
                          />
                          <label htmlFor={`role-${index}`} style={{ fontSize: "12px" }}>
                            {filter}
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </>
      )}
          <button className="btn btn-outline-danger mb-4 m-auto" onClick={handleClearAll}>Clear All</button>
    </div>
  );
}

export default Filters;
