import React, { useContext, useEffect, useState } from "react";
import "../../styles/home.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../buttons/SubmitButton";
import { Placeholder } from "react-bootstrap";
import error_image from "../../assets/images/error_images.png";
import userContext from "../../context";
import { Helmet } from "react-helmet";


function Index() {
  const [data, setData] = useState([]);
  const [loadMore, setLoadMore] = useState(6);
  const [show, setShow] = useState(false);
  const [selectCategory, setSeletedCategory] = useState({});
  const [subcategory, setSubCategory] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();
  const [categoryid, setCategoryid]=useState("")
 const { setCategoryId} = useContext(userContext);

  const handleClose = () => setShow(false);

  const handleShow = (item) => {
    // setShow(true);
    // setSeletedCategory(item);
    // setCategoryid(item?._id)
    // axios
    //   .get(`${API_BASE_URL}/getsubcategorybycategory/${item._id}`)
    //   .then((response) => {
    //     setSubCategory(response.data.Subcategories);
      
    //     console.log("select SubCategory", response.data.Subcategories);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching subcategory data:", error);
    //   });
    navigate(`/${item?.Main_Category}`)
    console.log("category name is ========>",item?.Main_Category)
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallmaincategory?limit=${loadMore}`)
      .then((res) => setData(res.data.mainCategories))
      .catch((error) => {
        console.log("error", error);
      });
  }, [loadMore]);

  const handleSelectSubCategory = (subCategory) => {
    const categorySlug = selectCategory.Main_Category.trim()
      .replace(/\s+/g, "-")
      .toLowerCase();

    const subCategoryName = subCategory.SubCategory.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    // navigate(`/${categorySlug}/${slug}`);

    navigate(`/${categorySlug}/${subCategoryName}`);
    // navigate(`/${subCategoryName}`);
  };

  const loadMoreItems = () => {
    if (initialLoad) {
      setLoadMore((prev) => prev + 6);
      setInitialLoad(false);
    } else {
      setLoadMore(6);
      setInitialLoad(true);
    }
  };
  const subCategoryDescriptions = subcategory.map(subCategory => subCategory.SubCategory).join(" | ");
  return (
    <div className="container pb-3">
      {data.length > 0 && (
        <div className="category-container row">
          {data.map((item, i) => (
            <div
              onClick={() => handleShow(item)}
              className="category col-md-2"
              key={i}
            >
              <img src={item.Main_Category_icon} alt="category-icon" />
              <h6 className="category-name m-0">{item.Main_Category}</h6>
              <p className="m-0 category-text" >{item.Description}</p>
            </div>
          ))}
        </div>
      )}

      {data.length === 0 && (
        <div className="category-container row">
          {[...Array(6)].map((_, i) => (
            <div className="category col-md-2" key={i}>
              <Placeholder as="div" animation="glow">
                <img src={error_image} alt="category-icon" />
              </Placeholder>
              <Placeholder
                as="h6"
                animation="glow"
                className="category-name m-0"
              >
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder
                as="p"
                animation="glow"
                className="category-text m-0"
              >
                <Placeholder xs={7} /> <Placeholder xs={4} />
              </Placeholder>
            </div>
          ))}
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
              >
        <Modal.Header closeButton>
          <Modal.Title style={{width:"100%",textAlign:"center"}}>{selectCategory.Main_Category}
          <Helmet>
              <title>{selectCategory.Main_Category}</title>
              <meta name="description" content={subCategoryDescriptions} />
            </Helmet>
          </Modal.Title>
        
        
        
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {subcategory.length === 0 ? (
              <p className="text-danger">
                {selectCategory.Main_Category} Items Not Available...
              </p>
            ) : (
              <>
                {subcategory.map((subCategory, i) => (
                  <div className="col-sm-6" key={i}>
                    <h6
                      onClick={() => handleSelectSubCategory(subCategory)}
                      className="modal-subcategory"
                      style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}
                    >
                      {subCategory.SubCategory}
                    </h6>
                  </div>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {data.length > 0 && (
        <center>
          <SubmitButton onClick={loadMoreItems} data={initialLoad?"View More":"View Less"} />
        </center>
      )}
    </div>
  );
}

export default Index;
