// import React from "react";
// import { Footer, Header } from "./components";
// import { Outlet } from "react-router-dom";

// function Layout() {
//   return (
//     <>
//       <Header />
//       <Outlet />
//       <Footer />
//     </>
//   );
// }

// export default Layout;













import React from "react";
import { Footer, Header } from "./components";
import { Outlet } from "react-router-dom";
import BreadCrumbs from "./components/breadcrumb/Breadcrumbs";
import { Helmet } from "react-helmet";

function Layout() {
  return (
    <>
      <Header />
      <Helmet>
        <title> PIN AND FIND</title>
        <meta name="description" content="get info for our website"/>
        <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/>
      </Helmet>
      <BreadCrumbs/>
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
