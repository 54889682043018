// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../utils";
// import { formatDistanceToNow } from "date-fns";
// import AdsSubmitButton from "../components/buttons/AdsSubmitButton";
// import NotFound from "../components/loaders/NotFound";

// function SearchResults() {
//   const { category, location, keyword } = useParams();

//   const [data, setData] = useState([]);

//   const navigate = useNavigate();

//   useEffect(() => {

//     keyword.replace(/-/g,' ')
//     category.replace(/-/g,' ')
//     location.replace(/-/g,' ')

//     axios
//       .get(
//         `${API_BASE_URL}/user/filtersearch?postTittle=${keyword}&Location=${location}&PostCategory=${category}`
//       )
//       .then((res) => {
//         setData(res.data.Properties);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, [keyword]);

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const location = data.PostDetails?.Location?.replace(
//       /\s+/g,
//       "-"
//     ).toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   return (
//     <div className="container my-4">
//       <h2>Search Results..</h2>
//       <div className="row">
//         {data.length === 0 ? (
//           <NotFound />
//         ) : (
//           <>
//             {data?.map((item, index) => (
//               <div className="col-sm-6" key={index}>
//                 <div className="card-wrapper">
//                   <div className="card mb-3 h-100">
//                     <div className="row g-0">
//                       <div className="col-lg-4">
//                         <img
//                           src={item.PosterImage}
//                           className="img-fluid rounded-start card-image"
//                           alt="ads-image"
//                           onClick={() => handleSingleProduct(item)}
//                         />
//                       </div>
//                       <div className="col-lg-8">
//                         <div className="card-body">
//                           <h5
//                             className="card-title"
//                             onClick={() => handleSingleProduct(item)}
//                           >
//                             {item.postTittle}
//                           </h5>
//                           <div className="card-spaks">
//                             {item.Descriptions?.slice(0, 3).map((item, i) => (
//                               <div key={i}>
//                                 <label htmlFor="">{item.name}</label>
//                                 <p className="m-0">{item.value}</p>
//                               </div>
//                             ))}
//                           </div>

//                           <p className="card-text m-0 py-2">
//                           <span style={{display:"block",fontWeight:"2px"}} className="mx-1">Description</span>
//                             <small className="text-body-secondary">
//                               {item.postDescription}
//                             </small>
//                           </p>
//                         </div>
//                         <div className="card-footer">
//                           <p className="m-0 ">
//                             <span className="mx-1">Posted:</span>
//                             {formatDistanceToNow(new Date(item.createdAt), {
//                               addSuffix: true,
//                             })}
//                             <span className="mr-1">by a company</span>
//                           </p>

//                           <div>
//                             <AdsSubmitButton item={item} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchResults;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../utils";
// import { formatDistanceToNow } from "date-fns";
// import AdsSubmitButton from "../components/buttons/AdsSubmitButton";
// import NotFound from "../components/loaders/NotFound";

// function SearchResults() {
//   const { category, location, keyword } = useParams();
//   const [data, setData] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const formattedKeyword = keyword.replace(/-/g, ' ');
//     const formattedCategory = category.replace(/-/g, ' ');
//     const formattedLocation = location.replace(/-/g, ' ');

//     axios
//       .get(
//         `${API_BASE_URL}/user/filtersearch?postTittle=${formattedKeyword}&Location=${formattedLocation}&PostCategory=${formattedCategory}`
//       )
//       .then((res) => {
//         setData(res.data.Properties);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, [keyword, category, location]);

//   const handleSingleProduct = (data) => {
//     const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
//     const subCategory = data.PostSubCategory?.replace(/\s+/g, "-").toLowerCase();
//     const location = data.PostDetails?.Location?.replace(/\s+/g, "-").toLowerCase();
//     const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
//     navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
//   };

//   return (
//     <div className="container my-4">
//       <h2>Search Results..</h2>
//       <div className="row">
//         {data.length === 0 ? (
//           <NotFound />
//         ) : (
//           <>
//             {data?.map((item, index) => (
//               <div className="col-sm-6" key={index}>
//                 <div className="card-wrapper">
//                   <div className="card mb-3 h-100">
//                     <div className="row g-0">
//                       <div className="col-lg-4">
//                         <img
//                           src={item.PosterImage}
//                           className="img-fluid rounded-start card-image"
//                           alt="ads-image"
//                           onClick={() => handleSingleProduct(item)}
//                         />
//                       </div>
//                       <div className="col-lg-8">
//                         <div className="card-body">
//                           <h5
//                             className="card-title"
//                             onClick={() => handleSingleProduct(item)}
//                           >
//                             {item.postTittle}
//                           </h5>
//                           <div className="card-spaks">
//                             {item.Descriptions?.slice(0, 3).map((item, i) => (
//                               <div key={i}>
//                                 <label htmlFor="">{item.name}</label>
//                                 <p className="m-0">{item.value}</p>
//                               </div>
//                             ))}
//                           </div>

//                           <p className="card-text m-0 py-2">
//                           <span style={{display:"block",fontWeight:"2px"}} className="mx-1">Description</span>
//                             <small className="text-body-secondary">
//                               {item.postDescription}
//                             </small>
//                           </p>
//                         </div>
//                         <div className="card-footer">
//                           <p className="m-0 ">
//                             <span className="mx-1">Posted:</span>
//                             {formatDistanceToNow(new Date(item.createdAt), {
//                               addSuffix: true,
//                             })}
//                             <span className="mr-1">by a company</span>
//                           </p>

//                           <div>
//                             <AdsSubmitButton item={item} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchResults;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import { formatDistanceToNow } from "date-fns";
import AdsSubmitButton from "../components/buttons/AdsSubmitButton";
import NotFound from "../components/loaders/NotFound";

function SearchResults() {
  const { category, location, keyword } = useParams();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let url = `${API_BASE_URL}/user/filtersearch`;

    if (keyword && category && location) {
      const formattedKeyword = keyword.replace(/-/g, " ");
      const formattedCategory = category.replace(/-/g, " ");
      const formattedLocation = location.replace(/-/g, " ");
      url += `?postTittle=${formattedKeyword}&Location=${formattedLocation}&PostCategory=${formattedCategory}`;
    } else if (category && location) {
      const formattedCategory = category.replace(/-/g, " ");
      const formattedLocation = location.replace(/-/g, " ");
      url += `?Location=${formattedLocation}&PostCategory=${formattedCategory}`;
    } else if (keyword && category) {
      const formattedKeyword = keyword.replace(/-/g, " ");
      const formattedCategory = category.replace(/-/g, " ");
      url += `?postTittle=${formattedKeyword}&PostCategory=${formattedCategory}`;
    }

    axios
      .get(url)
      .then((res) => {
        setData(res.data.Properties);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [keyword, category, location]);

  const handleSingleProduct = (data) => {
    const category = data.PostCategory?.replace(/\s+/g, "-").toLowerCase();
    const subCategory = data.PostSubCategory?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const location = data.PostDetails?.Location?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
    const adsSlug = data?.slug?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/${category}/${subCategory}/${location}/${adsSlug}`);
  };

  return (
    <div className="container my-4">
      <h2>Search Results..</h2>
      <div className="row">
        {data.length === 0 ? (
          <NotFound />
        ) : (
          <>
            {data?.map((item, index) => (
              <div className="col-sm-6" key={index}>
                <div className="card-wrapper">
                  <div className="card mb-3 h-100">
                    <div className="row g-0">
                      <div className="col-lg-4">
                        <img
                          src={item.PosterImage}
                          className="img-fluid rounded-start card-image"
                          alt="ads-image"
                          onClick={() => handleSingleProduct(item)}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            onClick={() => handleSingleProduct(item)}
                          >
                            {item.postTittle}
                          </h5>
                          <div className="card-spaks">
                            {item.Descriptions?.slice(0, 3).map((item, i) => (
                              <div key={i}>
                                <label htmlFor="">{item.name}</label>
                                <p className="m-0">{item.value}</p>
                              </div>
                            ))}
                          </div>

                          <p className="card-text m-0 py-2">
                            <span
                              style={{ display: "block", fontWeight: "2px" }}
                              className="mx-1"
                            >
                              Description
                            </span>
                            <small className="text-body-secondary">
                              {item.postDescription}
                            </small>
                          </p>
                        </div>
                        <div className="card-footer">
                          <p className="m-0 ">
                            <span className="mx-1">Posted:</span>
                            {formatDistanceToNow(new Date(item.createdAt), {
                              addSuffix: true,
                            })}
                            <span className="mr-1">by a company</span>
                          </p>

                          <div>
                            <AdsSubmitButton item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
