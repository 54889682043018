import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import { useNavigate } from "react-router-dom";
import userContext from "../../context";
import "../../styles/profile.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SubmitButton from "../../components/buttons/SubmitButton";
import {  MyAds, MyProfile } from "../../components";
import ResponseOtherAds from "../../components/profileComponents/ResponseOtherAds";
import MyResponse from "../../components/profileComponents/MyResponse";

function Profile() {
  const headers = useAuthHeaders();
  const [profileData, setProfileData] = useState();
  const profileContext = useContext(userContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const profileActive = profileContext.profileActive;

  const token=localStorage?.getItem("token")
  const header = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/myprofile`, { headers:header })
      .then((res) => {
        setProfileData(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }, [headers, profileActive, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    // navigate("/login");
    navigate("/");
  };

  const toggleTab = (tabName) => {
    setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
  };

  // const breadcrumbItems = [
  //   { path: ``, label: "home" },
  //   { path: ``, label: "profile" },
  // ];

  return (
    <>
      <div className="container my-3 profile">
        {/* <Breadcrumb items={breadcrumbItems} /> */}

        <div className="py-5 row">
          <div className="col-md-3">
            <ul
              className="nav nav-pills nav-pills me-3 align-items-start"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link text-primary fw-semibold position-relative ${
                    activeTab === "profile" ? "active" : ""
                  }`}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={activeTab === "profile"}
                  onClick={() => toggleTab("profile")}
                >
                  My Profile
                </button>
              </li>

              <li
                className="nav-item"
                role="presentation"
                onClick={() => toggleTab("ads")}
              >
                <button className="nav-link text-primary fw-semibold">
                  My Pind Ads
                </button>
              </li>

              {activeTab === "ads" && (
                <li className="nav-item-menu">
                  <button
                    className="nav-link nav-link-btn text-primary fw-semibold position-relative"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected={activeTab === "myAds"}
                    onClick={() => toggleTab("myAds")}
                  >
                    My Ads
                  </button>
                </li>
              )}

              <li
                className="nav-item"
                role="presentation"
                onClick={() => toggleTab("response")}
              >
                <button className="nav-link text-primary fw-semibold">
                  My Response
                </button>
              </li>

              {activeTab === "response" && (
                <>
                  <li className="nav-item-menu">
                    <button
                      className="nav-link text-primary fw-semibold position-relative"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected={activeTab === "repliesToMyAds"}
                      onClick={() => toggleTab("repliesToMyAds")}
                    >
                      Response to my ads
                    </button>
                  </li>
                  <li className="nav-item-menu">
                    <button
                      className="nav-link text-primary fw-semibold position-relative"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected={activeTab === "repliesToOthersAds"}
                      onClick={() => toggleTab("repliesToOthersAds")}
                    >
                      Response to others ads
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="col-md-9">
            <div
              className="tab-content text-danger w-100"
              id="pills-tabContent"
            >
              {activeTab === "profile" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <MyProfile profileData={profileData} />
                </div>
              )}

              {activeTab === "myAds" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <MyAds />
                </div>
              )}

              {activeTab === "repliesToMyAds" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <MyResponse />
                </div>
              )}

              {activeTab === "repliesToOthersAds" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <ResponseOtherAds />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-end">
          <SubmitButton data="Logout" onClick={handleLogout} />
        </div>
      </div>
    </>
  );
}

export default Profile;
