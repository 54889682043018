import React from "react";
import { Link } from "react-router-dom";

function SuggesLink({ path, data, pageName }) {
  return (
    <div className="sugges-link">
      <p className="m-0">
        {data} <Link to={path}> {pageName}</Link>
      </p>
    </div>
  );
}

export default SuggesLink;
